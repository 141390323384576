import Home from "./pages/Home";
import Message from "./pages/Message";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Mission from "./pages/Mission";
import KurumsalYonetim from "./pages/KurumsalYonetim";
import IsGuvenligi from "./pages/IsGuvenligi";
import Kvkk from "./pages/Kvkk";
import Iletisim from "./pages/Iletisim";
import Insaat from "./pages/Insaat";
import Altyapi from "./pages/Altyapi";
import Sertifikalar from "./pages/Sertifikalar";
import Asfalt from "./pages/Asfalt";
import Grupsirketler from "./pages/Grupsirketler";
import Basindabiz from "./pages/Basindabiz";
import Politika from "./pages/Politika";
import KKSAydinlatma from "./pages/KKS-aydinlatma";
import InternetAydinlatma from "./pages/Internet-aydinlatma";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/baskanin-mesaji" element={<Message />} />
          <Route exact path="/misyonumuz-vizyonumuz" element={<Mission />} />
          <Route exact path="/kurumsal-yonetim" element={<KurumsalYonetim />} />
          <Route exact path="/is-guvenligi" element={<IsGuvenligi />} />
          <Route exact path="/kvkk" element={<Kvkk />} />
          <Route exact path="/iletisim" element={<Iletisim />} />
          <Route exact path="/insaat" element={<Insaat />} />
          <Route exact path="/altyapi" element={<Altyapi />} />
          {/* <Route exact path="/sertifikalar" element={<Sertifikalar />} /> */}
          <Route exact path="/asfalt" element={<Asfalt />} />
          <Route exact path="/referans" element={<Grupsirketler />} />
          <Route exact path="/basindabiz" element={<Basindabiz />} />
          <Route exact path="/politika" element={<Politika />} />
          <Route exact path="/kks-aydinlatma" element={<KKSAydinlatma />} />
          <Route
            exact
            path="/internet-aydinlatma"
            element={<InternetAydinlatma />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
