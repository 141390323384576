import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import iletisimImg from "../assets/kurumsal-yonetim.png";
const Iletisim = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={iletisimImg}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            İLETİŞİM
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 sm:flex block">
        <div className="container flex-1">
          <iframe
            className="w-full h-96"
            title="bamtas-konum"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.355566927437!2d32.772497!3d40.000504600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34b602df01b15%3A0x3a61c016bad55559!2sTr%20Invest%20Corner%202!5e0!3m2!1sen!2str!4v1659608642306!5m2!1sen!2str"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="container flex-1 sm:p-10">
          <div className="flex h-16 ;">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 transition-all mt-3"></div>
            <button className=" sm:text-2xl md:text-4xl pb-3 sm:pb-0 pl-5  text-black font-bold">
              BAMTAŞ İNŞAAT A.Ş.
            </button>
          </div>
          <p>
            İvedikköy Mahallesi Anadolu Bulvarı TR İnvest Corner 2 İş Merkezi
            No:151/22-20 <br />
            Yenimahalle/ANKARA
            <br></br>
            <br></br>
            444 82 71<br></br>
            <br></br> info@bamtas.com
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Iletisim;
