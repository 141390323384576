import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import messageBackground from "../assets/message-background/msg-bckg3.png";

const Message = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          alt="mesaj arkaplan görseli"
          id="hero-background"
          src={messageBackground}
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            BAŞKAN'IN MESAJI
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          Değerli İş Ortaklarımız ve Kıymetli Çalışanlarımız;
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Çevreye duyarlılık bilincini yitirmeden, toplumsal gelişime katkıda
          bulunarak insan hayatını zenginleştirecek projelere imza atmayı
          hedefleyen BAMTAŞ; bu amaç doğrultusunda çalışanlarına, iş ortaklarına
          ve dünyaya değer katmayı öncelik belirlemiştir. Önceliklerimiz
          arasında güçlü nakit akışını sağlayan etkin bir finansal yönetim,
          kaynakların verimli kullanımına yönelik stratejik planlama ve sağlıklı
          istihdam artışıyla çalışan memnuniyetini artırmak yer almaktadır. 2022
          yılında özellikle İnşaat sektöründe mevcut projelerimize yeni ilave
          olacak projeler geliştirmeyi ve Üst yapı alanındaki yatırımlarımızı da
          AR-GE çalışmalarımıza ağırlık vererek geliştirmeyi planlıyoruz. Bamtaş
          İnşaat olarak, her faaliyetimizle insan hayatına dokunmaya önem
          veriyoruz. Oldukça büyük olan bu sorumluluk, yasal sorumluluklarımızın
          ötesine geçerek faaliyetlerimizin ekseninde yer alıyor ve ben
          inanıyorum ki çalışanlarımızdan ortaklarımıza kadar tüm paydaşlarımız
          bu eksende birleşiyor.<br></br>
          <br></br> Bugüne kadar olduğu gibi bundan sonra da köklü kurumsal
          değerlerimizi koruyarak, faaliyette bulunduğumuz tüm coğrafyalarda
          toplum refahına katkıda bulunacak başarılara hep birlikte imza atmaya
          devam edeceğiz. Önümüzde uzun bir yol var. Diliyorum ki bu yolda büyük
          başarılara hep birlikte imza atar, ürettiğimiz değerlerin yükselişine
          tanıklık ederiz. <br></br>
          <br></br>Sevgi ve Saygılarımla, <br></br>
          <br></br>
          <span className="font-bold">Sercan ALATAŞ</span> / Yönetim Kurulu
          Başkanı
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Message;
