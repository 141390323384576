import React from "react";
import { useState } from "react";
import Logo from "../assets/beyaz-logo.png";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);

  const toPage = (route) => {
    navigate("/" + route);
  };

  return (
    <nav className="w-full bg-transparent absolute z-50">
      <div
        className={` justify-between px-4 mx-auto lg:max-w-7xl lg:items-center lg:flex lg:px-8 animate-fade-in-down${
          navbar && "  bg-black h-screen"
        } `}
      >
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 lg:block">
            <a href="/">
              <img src={Logo} alt="bamtas logo" className="w-14 sw-full "></img>
            </a>
            <div className="lg:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    color="white"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    color="white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-gray-600 hover:text-red-700">
                <button href="" className="peer flex items-center text-white">
                  Hakkımızda <BsChevronDown className="ml-1 w-3" />
                </button>
                <div className="animate-fade-in-down hidden peer-hover:flex absolute hover:flex w-[320px] flex-col bg-white drop-shadow-lg text-black border-t-2 border-red-700">
                  <button
                    className="px-5 py-3 hover:bg-gray-200 hover:text-red-700 border-b-2 "
                    onClick={() => {
                      toPage("baskanin-mesaji");
                    }}
                  >
                    Başkan'ın Mesajı
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("misyonumuz-vizyonumuz");
                    }}
                  >
                    Misyonumuz ve Misyonumuz
                  </button>

                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("referans");
                    }}
                  >
                    Grup Şirketler
                  </button>
                </div>
              </li>
              <li className="text-gray-600 hover:text-red-700">
                <button href="" className="peer flex items-center text-white">
                  Faaliyet Alanları <BsChevronDown className="ml-1 w-3" />
                </button>
                <div className="animate-fade-in-down hidden peer-hover:flex absolute hover:flex w-[320px] flex-col bg-white drop-shadow-lg text-black border-t-2 border-red-700">
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("insaat");
                    }}
                  >
                    Üstyapı
                  </button>

                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    href="#"
                    onClick={() => {
                      toPage("altyapi");
                    }}
                  >
                    Altyapı
                  </button>
                </div>
              </li>
              <li className="text-gray-600 hover:text-red-700">
                <button href="" className="peer flex items-center text-white">
                  Kurumsal <BsChevronDown className="ml-1 w-3" />
                </button>
                <div className="animate-fade-in-down hidden peer-hover:flex absolute hover:flex w-[320px] flex-col bg-white drop-shadow-lg text-black border-t-2 border-red-700">
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("kurumsal-yonetim");
                    }}
                  >
                    Kurumsal Yönetim ve İlkeleri
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("politika");
                    }}
                  >
                    Politika
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("kks-aydinlatma");
                    }}
                  >
                    KKS Aydinlatma Metni
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("internet-aydinlatma");
                    }}
                  >
                    İnternet Aydinlatma Metni
                  </button>
                  {/* <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("sertifikalar");
                    }}
                  >
                    Sertifikalar ve Belgeler
                  </button> */}
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    onClick={() => {
                      toPage("is-guvenligi");
                    }}
                  >
                    İş Güvenliği
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    href="#"
                    onClick={() => {
                      toPage("kvkk");
                    }}
                  >
                    Kişisel Verilerin Korunma Politikası
                  </button>
                  <button
                    className="px-5 py-3 hover:bg-gray-200  hover:text-red-700 border-b-2"
                    href="#"
                    onClick={() => {
                      toPage("basindabiz");
                    }}
                  >
                    Basında Biz
                  </button>
                </div>
              </li>
              <li className="text-gray-600 hover:text-red-700">
                <button
                  href=""
                  className="text-white"
                  onClick={() => {
                    toPage("iletisim");
                  }}
                >
                  İletişim
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
