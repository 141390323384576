import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import kurumsalYonetim from "../assets/kurumsal-yonetim.png";
const KKSAydinlatma = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kurumsalYonetim}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            KAMERA KAYIT SİSTEMLERİ AYDINLATMA METNİ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-center font-bold ">
          BAMTAŞ İNŞAAT TAAHHÜT MADEN SANAYİ TİCARET ANONİM ŞİRKETİ
        </h3>
        <h3 className="text-lg sm:text-xl text-center font-bold ">
          KAMERA KAYIT SİSTEMLERİ AYDINLATMA METNİ
        </h3>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          1- GENEL AÇIKLAMA VE TANIMLAR
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          İş bu bilgilendirme metni Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret
          Anonim Şirketi “veri sorumlusu” sıfatıyla, 6698 Sayılı Kişisel
          Verilerin Korunması Kanunu (Kanun)’nun 10 ncu maddesi kapsamında,
          şirket içi lokasyonlar gerçekleştirilen kamera kayıt sistemleri
          hakkında “aydınlatma yükümlülüğü” nün yerine getirilmesi amacıyla
          hazırlanmıştır.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          2- VERİ SORUMLUSUNA İLİŞKİN BİLGİLER
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Bamtaş İnşaat Taah. Maden San Tic. A.Ş. Türkiye’de yerleşik, tüzel
          kişiliği olan bir sermaye şirketi olarak faaliyetlerini yürütmektedir.
          Veri sorumlusu olarak Bamtaş İnşaat Taah. Maden San Tic. A.Ş. hakkında
          bilgiler aşağıda belirtildiği şekildedir. <br />
          <br />
          Unvanı : BAMTAŞ İNŞAAT TAAHHÜT MADEN SANAYİ TİCARET ANONİM ŞİRKETİ{" "}
          <br />
          <br />
          Vergi No : 140 039 71 25
          <br /> <br />
          Mersis Numarası : 0140039712500001 <br />
          <br />
          Adresi : İvedikköy Mah. Anadolu Bulv. No:151/22 Yenimahalle / ANKARA{" "}
          <br />
          <br />
          Telefonu : 444 82 71 <br />
          <br />
          Fax : e-posta adresi : info@bamtas.com <br />
          <br />
          Kayıtlı Elektronik Posta (KEP) : bamtas@hs01.kep.tr
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          3- İŞLENECEK KİŞİSEL VERİLER
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          işlenecek kişisel veriler aşağıda belirtilmiş olup kanunların gerekli
          kıldığı ve/veya ihtiyaç duyulduğu hallerde bu bilgilere yenileri ilave
          edilebilir ve/veya değişiklik yapılabilir. İşlenen kişisel veriler,
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi içerisinde
          belli lokasyonlarda bulunan görüntülü kamera kayıtlarıdır.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          4- KAMERA KAYITLARININ ELDE EDİLME YÖNTEMLERİ VE HUKUKİ SEBEBİ
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Meşru menfaatlerin korunması ve hakkın tesisi ve korunması sebebiyle,
          belli lokasyonlarında bulunan kameralar ile 7 gün 24 saat süre ile
          kamera kaydı temin edilebilmektedir.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          5- KAMERA KAYITLARININ İŞLENME AMACI
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Bina içi güvenliğin sağlanması, bina ve tesislerin yapılan girişlerin
          kontrol edilebilmesi,Verimliliğinin arttırılması,Şirket ile iş
          ilişkisi içerisinde olan kişilerin hukuki, ticari, teknik güvenliğinin
          sağlanması,İş ilişkisinin ispatlanması, iş sağlığı ve güvenliği
          esaslarının uygulanabilmesi, kanunlardan doğan yükümlülüklerin yerine
          getirilmesi, çalışma koşullarının belirlenmesi amacıyla kişisel
          veriler işlenmektedir.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          6- KAMERA KAYITLARININ AKTARIMI VE KORUNMASI
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi., veri
          işleme amaçları doğrultusunda elde etmiş olduğu kişisel verileri iş
          ortakları ve hukuken yetkili kurum ve kuruluşlarla
          paylaşabilecektir.Görüntülere yalnızca yetkili kişilerin
          bilgisayarlarından ulaşılabilmektedir.Verilerin korunması amacıyla
          gerekli tüm teknik önlemler alınmaktadır.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          7- KAMERA KAYITLARININ SAKLANMA SÜRESİ
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Kamere kayıtları 15 gün süre ile saklanmaktadır.
        </p>
        <br />
        <h3 className="text-base sm:text-xl  font-bold ">
          8- İLGİLİ KİŞİNİN HAKLARI
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          İlgili kişiler veri sorumlusuna başvurarak kendisiyle ilgili;
          <br />
          Kişisel veri işlenip işlenmediğini öğrenme,
          <br />
          Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
          <br />
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
          kullanılmadığını öğrenme,
          <br />
          Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
          kişileri bilme,
          <br />
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme,
          <br />
          Kanunda öngörülen şartlar çerçevesinde kişisel verilerin silinmesini
          veya yok edilmesini isteme,
          <br />
          Kişisel verilerle ilgili değişiklilerin, kişisel verilerin aktarıldığı
          üçüncü kişilere bildirilmesini isteme,
          <br />
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkmasına itiraz etme,
          <br />
          Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğraması hâlinde zararın giderilmesini talep etme,
          <br />
          haklarına sahiptir.
        </p><br/>
        <h3 className="text-base sm:text-xl  font-bold ">
          9- VERİ SORUMLUSUNA BAŞVURU
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Kişisel verisi işlenen gerçek kişiler, 6698 sayılı Kanunun 11 nci
          maddesinde belirtilen hakları kapsamındaki taleplerine ilişkin yazılı
          olarak başvurabilir.
          <br /> Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi
          başvuruda yer alan talepleri, talebin niteliğine göre en kısa sürede
          ve en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Ancak,
          işlemin ayrıca bir maliyet gerektirmesi hâlinde, “Veri Sorumlusuna
          Başvuru Usul Ve Esasları Hakkında Tebliğ” in 7’nci maddesinde
          belirtilen tutarda ücret alınabilir. Başvurunun, veri sorumlusunun
          hatasından kaynaklanması hâlinde alınan ücret ilgiliye iade edilir.
        </p><br/>
        <h3 className="text-base sm:text-xl  font-bold ">
          10- DEĞİŞİKLİK VE GÜNCELLEMELER
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          İş bu bilgilendirme metni, 6698 sayılı Kanun ve söz konusu Kanun
          kapsamında yayınlanmış olan Yönetmelik ve Tebliğler ile Bamtaş İnşaat
          Taahhüt Maden Sanayi Ticaret Anonim Şirketi kişisel veri işleme amaç
          ve politikaları kapsamında hazırlanmıştır. İlgili yasal mevzuat
          ve/veya Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.nin
          kişisel veri işleme amaç ve politikalarında meydana gelecek
          değişiklikler doğrultusunda bilgilendirme metninde gerekli
          değişiklikler yapılabilir.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default KKSAydinlatma;
