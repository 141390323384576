import React from "react";
import missionBackground from "../assets/hero-background.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import yapiIcon from "../assets/references/yapi-yapi.svg";
// import abbIcon from "../assets/references/abb.svg";
// import aslIcon from "../assets/references/asl.svg";
// import cengizIcon from "../assets/references/cengiz-insaat.svg";
// import sogutIcon from "../assets/references/sogut.svg";
// import uguralIcon from "../assets/references/ugural.svg";
// import ziverIcon from "../assets/references/ziver.svg";
const Mission = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          alt="arkaplan"
          id="hero-background"
          src={missionBackground}
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            MİSYONUMUZ VE VİZYONUMUZ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 flex-col sm:flex">
        <div id="mission-text" className="px-4 ">
          <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
            Misyonumuz
          </h3>
          <p className="text-justify pt-12 text-base sm:text-2xl">
            "Kaliteli, yaşanılabilir çevreye saygılı projelerde yer almak"
            felsefesiyle yola çıkan BAMTAŞ İnşaat A.Ş. amacına uygun olarak
            gerçekleştirdiği bütün projelerde kaliteli, modern, estetik yaşam
            alanları ile bulunduğu bölgeye değer katmak, müşterilerin
            beklentilerine uygun projeler üretmek ve koşulsuz memnuniyet
            ilkesiyle hizmet vermek, teknolojiyi yakından takip ederek makine
            parkurunu yenilemek ve projelerine entegre etmek, dünya
            standartlarında, güvenli yaşam alanları oluşturmak için
            çalışmaktadır.
          </p>
        </div>
        <div id="vision-text" className="px-4 ">
          <br></br>
          <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
            Vizyonumuz
          </h3>
          <p className="text-justify pt-12 text-base sm:text-2xl">
            Yarım asra yaklaşan geçmişimizden aldığımız bilgi ve deneyimi,
            nitelikli insan kaynağı ve yenilikçi yaklaşımla birleştirerek
            Türkiye'nin en çok tercih edilen hafriyat ve inşaat şirketleri
            arasında olmaktır.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Mission;
