import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import insaatBg from "../assets/hero-background.png";
import insaatIcon from "../assets/altyapi-icon.png";
import projectsData from "../ProjectData/projects.json";
import Project from "../components/Project/Project";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
const Altyapi = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={insaatBg}
          alt="ınsaat arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
          ALTYAPI
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 sm:flex">
        <div className="flex-1">
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 transition-all"></div>
            <h3 className=" sm:text-2xl md:text-4xl pb-3 sm:pb-0 pl-5 text-center  text-black font-bold">
              Asfalt ve Yol Çalışmaları
            </h3>
          </div>
          <p className="pt-5 text-justify text-sm sm:text-lg">
            Bünyesinde barındırdığı uzman ekip ve makine parkıyla Türkiyenin her
            yerinde iş yapabilme gücüne sahip olan BAMTAŞ İNŞAAT, müşteri
            memnuniyeti ilkesi doğrultusunda yoluna emin adımlarla devam
            etmektedir.Profesyonel çözüm üretme özelliği, deneyimli kadrosu,
            kendini sürekli geliştiren yenilikçi yapısı ve geçmişinden gelen
            bilgi birikimi ile büyüyen ve gelişen; asfalt kaplama sektörüne uzun
            yıllar değer katmayı hedefleyen bir şirkettir.
          </p>
        </div>
        <div className="flex items-center justify-center flex-1">
          <div>
            <img
              alt="insaat ikon"
              src={insaatIcon}
              className={"aspect-auto hidden sm:block"}
            ></img>
          </div>
        </div>
      </div>
      <div className="container m-auto flex flex-col sm:flex-row">
        <AutoplaySlider
          play={true}
          interval={6000}
          animation="cubeAnimation"
          className="w-full aspect-auto object-cover h-full z-[20_!important]"
        >
          <div className="">
            <img src="assets/asfalt/1.png" className="w-full"></img>
          </div>
          <div className="">
            <img src="assets/asfalt/2.png" className="w-full"></img>
          </div>
          <div className="">
            <img src="assets/asfalt/3.png" className="w-full"></img>
          </div>
        </AutoplaySlider>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 sm:flex">
        <div className="flex-1">
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 transition-all"></div>
            <h3 className=" sm:text-2xl md:text-4xl pb-3 sm:pb-0 pl-5 text-center  text-black font-bold">
              Altyapı
            </h3>
          </div>
          <p className="pt-5 text-justify text-sm sm:text-lg">
            Firmamızın faaliyet konularından birisi olan altyapı projeleri ve
            uygulamalarıdır. Altyapı projeleri istenilen boru çapı ve
            büyüklüklerinde gerekli alan kazıları ve alan kotlamaları yapılarak
            projesine uygun olarak çizim ve uygulamaları yapılmaktadır.
          </p>
        </div>
        <div className="flex items-center justify-center flex-1">
          <div>
            <img
              alt="insaat ikon"
              src={insaatIcon}
              className={"aspect-auto hidden sm:block"}
            ></img>
          </div>
        </div>
      </div>
      <div className="container m-auto flex flex-col sm:flex-row">
        <AutoplaySlider
          play={true}
          interval={6000}
          animation="cubeAnimation"
          className="w-full aspect-auto object-cover h-full z-[20_!important]"
        >
          <div className="w-full h-full">
            <img src="assets/hero-background.png" className="w-full h-full"></img>
          </div>
          <div className="w-full h-full">
            <img src="assets/project1.png" className="w-full h-full"></img>
          </div>
          <div className="w-full h-full">
            <img src="assets/altyapi/3.jpeg" className="w-full h-full"></img>
          </div>
          <div className="w-full h-full">
            <img src="assets/altyapi/4.jpeg" className="w-full"></img>
          </div>
          <div className="w-full h-full">
            <img src="assets/altyapi/5.jpeg" className="w-full"></img>
          </div>
          <div className="w-full h-full">
            <img src="assets/altyapi/6.jpeg" className="w-full"></img>
          </div>
        </AutoplaySlider>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h5 className="container text-red-700 sm:text-2xl font-bold">
          Projemiz
        </h5>

        {projectsData.map((e, index) => {
          if (e.category === "altyapi") {
            return (
              <div className="border-b-2 border-red-700 mb-8" key={index}>
                {" "}
                <Project projectInfo={e} />
              </div>
            );
          } else return null;
        })}
      </div>
      <Footer />
    </>
  );
};

export default Altyapi;
