import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import isGuvenligiImg from "../assets/is-guvenligi.png";
const IsGuvenligi = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={isGuvenligiImg}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
           İŞ GÜVENLİĞİ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          İş Sağlığı ve Güvenliği Politikası
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          BAMTAŞ İNŞAAT , etkin ve verimli bir yönetim ve çalışma ortamının
          oluşturulmasında sağlık ve güvenlik koşullarının sağlanmasına öncelik
          vermektedir. BAMTAŞ, iş kazası halinde gerekli tüm inceleme ve
          denetlemelerin mevzuata uygun ve en hızlı biçimde yürütülmesine özen
          göstermektedir. Faaliyetlerini insan yaşamına saygı çerçevesinde
          gerçekleştiren BAMTAŞ İNŞAAT , bünyesinde yer alan tüm çalışanların,
          taşeronların ve çözüm ortaklarının iş sağlığını ve güvenliğini odak
          noktasına yerleştiren bir anlayışla hareket etmesine büyük bir önem
          atfetmektedir. Bu bağlamda, etkin ve verimli bir yönetim ve çalışma
          ortamının oluşturulmasında sağlık ve güvenlik koşullarının
          sağlanmasına öncelik vermektedir. BAMTAS, sahip olduğu bu
          farkındalıkla tüm çalışmalarını Uluslararası İş Sağlığı ve Güvenliği
          Yönetim Standardı OHSAS 18001 kapsamında yürütmektedir. BAMTAŞ İNŞAAT
          çatısı altında yer alan şirketlerde iş sağlığı ve güvenliği için
          gerekli fiziki koşullar ilgili mevzuata uygun biçimde ve özenle tesis
          edilmektedir. BAMTAŞ bünyesindeki çalışanlara ve taşeron şirketlere
          verilen iş güvenliği eğitimleri, her bir bireyde yalnızca kendilerinin
          değil başkalarının da sağlık ve güvenliklerinden sorumlu oldukları
          bilincini pekiştirmektedir. İş güvenliği konusunda bilgi birikimine
          sahip olan çalışanlar, daha güvenli bir çalışma ortamına katkı
          sağlamaktadırlar. BAMTAŞ İNŞAAT iş kazası halinde gerekli tüm inceleme
          ve denetlemelerin mevzuata uygun ve en hızlı biçimde yürütülmesine
          özen göstermektedir. Kaza nedenlerinin detaylı analizleri neticesinde
          risk yönetimi sistemleri geliştirilmekte ve gerekli hallerde
          prosedürler, taşeron ve malzeme seçimi ile iş süreçlerinin yenilenmesi
          dâhil olmak üzere birçok husus tekrar değerlendirmeye tabi
          tutulmaktadır.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default IsGuvenligi;
