import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroSection from "../components/home/HeroSection";
import ProjectSection from "../components/home/ProjectsSection/ProjectSection";
import Promo from "../components/home/Promo";
import Numbers from "../components/home/Numbers";
const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Numbers />
      <ProjectSection />
      <Promo />
      <Footer />
    </div>
  );
};

export default Home;
