import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import kurumsalYonetim from "../assets/kurumsal-yonetim.png";
const Politika = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kurumsalYonetim}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            POLİTİKAMIZ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          1. AMAÇ VE KAPSAM
        </h3>
        <p className="text-justify  text-base sm:text-2xl">
          KVKK uyarınca veri işleyen ve veri sorumlusu sıfatıyla Bamtaş İnşaat
          Taahhüt Maden Sanayi Ticaret Anonim Şirketi olarak; 6698 Sayılı
          Kişisel Verilerin Korunması Kanunu'na uygun olarak elde etmiş
          olduğumuz kişisel verileri, yine bu Kanuna ve Anayasaya uygun olarak
          işleyip, bu verilerin işlenmesi, korunması,silinmesi ve güvenliği
          konusunda gerekli hassasiyeti göstermekteyiz. <br /> <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi olarak
          çalışanlarımızın, tedarikçilerimizin, müşterilerimizin, hizmet
          alıp-hizmet verdiğimiz kişilerin, taşeronlarımızın, internet sitemizi
          ziyaret eden kullanıcılarımızın, ayrıca bunlarla sınırlı olmamak
          üzere, faaliyetlerimiz sırasında edindiğimiz 3.kişilere ait tüm
          kişisel verilerin işlenmesi, saklanması, aktarılması ve silinmesi
          esnasında 6698 sayılı kanun hükümlerine uygun hareket edilmektedir.Bu
          bağlamda yapılan her türlü işlemin hukuka uygun olması için şirket
          olarak gerekli teknik ve idari önlemleri almaktayız.
          <br />
          <br /> Kişisel Verileri Saklama ve İmha Politikası (“Politika”),
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          gerçekleştirilmekte olan işleme,saklama ve imha faaliyetlerine ilişkin
          iş ve işlemler konusunda usul ve esasları belirlemek amacıyla
          hazırlanmıştır. Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi kişilere ait kişisel verilerin T.C. Anayasası, uluslararası
          sözleşmeler, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”)
          ve diğer ilgili mevzuata uygun olarak işlenmesini ve ilgili kişilerin
          haklarını etkin bir şekilde kullanmasının sağlanmasını öncelik olarak
          belirlemiştir. Kişisel verilerin saklanması ve imhasına ilişkin iş ve
          işlemler, Bamtaş İnşaat Taah. Maden San Tic. A.Ş. tarafından bu
          doğrultuda hazırlanmış olan Politikaya uygun olarak gerçekleştirilir.
        </p>
        <br></br>
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          TANIMLAR
        </h3>
        <br />
        <table className="table-fixed text-justify pt-12 text-base sm:text-2xl px-2 py-2">
          <tbody>
            <tr>
              <td className="border px-2 font-bold">BAMTAŞ A.Ş</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi (Vergi
                Numarası:140 039 71 25 Mersis Numarası 0140039712500001, Adres:
                İvedikköy Mah. Anadolu Bulv. No:151/22 Yenimahalle / ANKARA.,
                Telefon: 444 82 71 E-Posta Adresi : info@bamtas.com Kayıtlı
                Elektronik Posta bamtas@hs01.kep.tr)
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold ">Açık Rıza</td>
              <td className="border px-2">
                Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür
                iradeyle açıklanan rıza.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Anonim Hale Getirme </td>
              <td className="border px-2">
                Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir
                surette kimliği belirli veya belirlenebilir bir gerçek kişiyle
                ilişkilendirilemeyecek hâle getirilmesi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Çalışan </td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.
                Personeli
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Çalışan Adayı</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.'ne
                herhangi bir yolla iş başvurusunda bulunmuş ya da özgeçmiş ve
                ilgili bilgilerini şirketimizin incelemesine açmış olan gerçek
                kişiler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Grup Şirketleri</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.’nin
                doğrudan ya da dolaylı bağlı ortaklıkları, iştirakleri,
                yönetiminde söz hakkı sahibi olduğu iş ortaklıkları ile Bamtaş
                İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi bünyesinde
                yer alan tüm şirketler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">İmha</td>
              <td className="border px-2">
                Kişisel verilerin silinmesi, yok edilmesi veya anonim hale
                getirilmesi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">İş Ortağı</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.’nin
                ticari faaliyetlerini yürütürken, birlikte projeler
                yürütmek,ihaleye katılmak,hizmet vermek-hizmet almak gibi
                amaçlarla iş ortaklığı kurduğu taraflar.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Kanun/KVKK</td>
              <td className="border px-2">
                6698 Sayılı Kişisel Verilerin Korunması Kanunu
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Kişisel Veri</td>
              <td className="border px-2">
                Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her
                türlü bilgi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Kişisel Veri İşleme Envanteri
              </td>
              <td className="border px-2">
                Veri sorumlularının iş süreçlerine bağlı olarak
                gerçekleştirmekte oldukları kişisel verileri işleme
                faaliyetlerini; kişisel verileri işleme amaçları ve hukuki
                sebebi, veri kategorisi, aktarılan alıcı grubu ve veri konusu
                kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel
                verilerin işlendikleri amaçlar için gerekli olan azami muhafaza
                edilme süresini, yabancı ülkelere aktarımı öngörülen kişisel
                verileri ve veri güvenliğine ilişkin alınan tedbirleri
                açıklayarak detaylandırdıkları envanter.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Kişisel Verilerin İşlenmesi
              </td>
              <td className="border px-2">
                Kişisel verilerin tamamen veya kısmen otomatik olan ya da
                herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
                otomatik olmayan yollarla elde edilmesi, kaydedilmesi,
                depolanması, muhafaza edilmesi, değiştirilmesi, yeniden
                düzenlenmesi, açıklanması, aktarılması, devralınması, elde
                edilebilir hâle getirilmesi, sınıflandırılması ya da
                kullanılmasının engellenmesi gibi veriler üzerinde
                gerçekleştirilen her türlü işlem.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Kişisel Verilerin Silinmesi
              </td>
              <td className="border px-2">
                Kişisel verilerin silinmesi, kişisel verilerin ilgili
                kullanıcılar için hiçbir şekilde erişilemez ve tekrar
                kullanılamaz hale getirilmesi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Kişisel Verilerin Yok Edilmesi
              </td>
              <td className="border px-2">
                Kişisel verilerin yok edilmesi, kişisel verilerin hiç kimse
                tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar
                kullanılamaz hale getirilmesi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Kişisel Verilerin Anonim Hale Getirilmesi
              </td>
              <td className="border px-2">
                Kişisel verilerin anonim hale getirilmesi, kişisel verilerin
                başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli
                veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek
                hale getirilmesi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Özel Nitelikli Kişisel Veri
              </td>
              <td className="border px-2">
                Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı,
                dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek,
                vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza
                mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile
                biyometrik ve genetik veriler.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Politika</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi
                tarafından, Kanun uyarınca hazırlanan, işbu kişisel verilerin
                korunması ve işlenmesi ve imha politikası.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Tedarikçi</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi 'nin
                ticari faaliyetlerini yürütürken Bamtaş İnşaat Taahhüt Maden
                Sanayi Ticaret Anonim Şirketi .’nin emir ve talimatlarına uygun
                olarak, sözleşme temelli olarak, hizmet sunan taraflar.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Veri işleyen</td>
              <td className="border px-2">
                Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel
                verileri işleyen gerçek veya tüzel kişi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Veri Kayıt Sistemi</td>
              <td className="border px-2">
                Kişisel verilerin belirli kriterlere göre yapılandırılarak
                işlendiği kayıt sistemini
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Veri Sorumlusu</td>
              <td className="border px-2">
                Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen,
                veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu
                olan gerçek veya tüzel kişi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Veri Sahibi/İlgili Kişi</td>
              <td className="border px-2">
                Kişisel verisi işlenen gerçek kişi.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Ziyaretçi</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.’nin
                faaliyet gösterdiği fiziksel yerleşkelere çeşitli amaçlarla
                girmiş olan veya internet sitelerimizi ziyaret eden gerçek
                kişiler.
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          2. KİŞİSEL VERİLERİN KATEGORİZASYONU
        </h3>
        <br />
        <table className="table-fixed text-justify pt-12 text-base sm:text-2xl px-2 py-2">
          <tbody>
            <tr>
              <td className="border px-2 font-bold">
                KİŞİSEL VERİ KATEGORİZASYONU
              </td>
              <td className="border px-2 font-bold">AÇIKLAMA</td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Kimlik Bilgisi</td>
              <td className="border px-2">
                ad-soyad, T.C. kimlik numarası, uyruk bilgisi, anne adı-baba
                adı, doğum yeri, doğum tarihi, cinsiyet gibi bilgileri içeren
                ehliyet, nüfus cüzdanı ve pasaport gibi belgeler ile vergi
                numarası, SGK numarası v.b. bilgiler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold ">İletişim Bilgisi</td>
              <td className="border px-2">
                telefon numarası, adres, e-mail adresi, faks numarası, IP adresi
                gibi bilgiler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Aile Bireyleri ve Yakın Bilgisi
              </td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.nin iş
                birimleri tarafından yürütülen faaliyetlerde, sunulan ürün ve
                hizmetlerle ilgili veya Bamtaş İnşaat Taahhüt Maden Sanayi
                Ticaret Anonim Şirketi.ve kişisel veri sahibinin hukuki ve diğer
                menfaatlerini korumak amacıyla kişisel veri sahibinin aile
                bireyleri (örn. eş, anne, baba, çocuk), yakınları ve acil
                durumlarda ulaşılabilecek diğer kişiler hakkındaki bilgiler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Finansal Bilgi</td>
              <td className="border px-2">
                kişisel veri sahibi ile olan hukuki ilişkinin tipine göre
                yaratılan her türlü finansal sonucu gösteren bilgi, belge ve
                kayıtlara ilişkin işlenen kişisel veriler ile banka hesap
                numarası, IBAN numarası, kredi kartı bilgisi, finansal profil,
                malvarlığı verisi, gelir bilgisi gibi veriler
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Görsel Bilgi</td>
              <td className="border px-2">fotoğraf</td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Özlük Bilgisi</td>
              <td className="border px-2">
                çalışma ilişkisi içerisinde olan gerçek kişilerin özlük
                haklarının oluşmasına temel olacak bilgilerin elde edilmesine
                yönelik işlenen her türlü kişisel veri
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">
                Özel Nitelikli Kişisel Veri
              </td>
              <td className="border px-2">
                KVK Kanunu’nun 6. maddesinde belirtilen veriler (örn. kan grubu
                da dahil sağlık verileri, biyometrik veriler, din ve üye olunan
                dernek bilgisi gibi)
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          3-KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN USUL VE ESASLAR
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Kişisel veriler Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi tarafından , Anayasa ve 6698 Sayılı Kişisel Verilerin
          Korunması Hakkındaki Kanun'a uygun olarak ve yine bu kanunda
          düzenlenen ve aşağıda sıralanmış olan temel ilkelere sağdık kalarak
          işlenmektedir.Temel İlkeler:
        </p>
        <p className="text-justify pt-12 text-base sm:text-2xl font-semibold">
          1-Hukuka ve Dürüstlük Kuralına Uygun İşleme:
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          Kişisel Veriler’in işlenmesinde, tüm hukuki düzenlemeler ve dürüstlük
          kuralına uygun hareket edilmektedir.
        </p>
        <p className="text-justify pt-12 text-base sm:text-2xl font-semibold">
          2-Kişisel Veriler’in Doğru ve Gerektiğinde Güncel Olmasını Sağlama
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          İşlenen Kişisel Veriler’in doğru ve güncel duruma uygun olması için
          gerekli tedbirler alınmakta ve işlenmekte olan verilerin gerçek durumu
          yansıtmasını sağlamak amacıyla bilgilendirmelerde bulunularak Veri
          Sahipleri’ne gerekli imkânlar tanınmaktadır.
        </p>
        <p className="text-justify pt-12 text-base sm:text-2xl font-semibold">
          3-Belirli, Açık ve Meşru Amaçlarla İşleme
        </p>{" "}
        <p className="text-justify  text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi Meşru ve
          hukuka uygun olan kişisel veri işleme amacını açık ve kesin olarak
          belirlemektedir. Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi. yürütmekte olduğu ticari faaliyet ile bağlantılı ve bunlar
          için gerekli olan kadar veri işlemektedir.
        </p>
        <p className="text-justify pt-12 text-base sm:text-2xl font-semibold">
          4-İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma
        </p>{" "}
        <p className="text-justify  text-base sm:text-2xl">
          Kişisel Veriler belirlenen amaçların gerçekleştirilebilmesine
          elverişli bir biçimde işlemekte ve amacın gerçekleştirilmesiyle ilgili
          olmayan veya ihtiyaç duyulmayan Kişisel Veriler’in işlenmesinden
          kaçınılmaktadır.
        </p>
        <p className="text-justify pt-12 text-base sm:text-2xl font-semibold">
          5-İlgili Mevzuatta Öngörülen Veya İşlendikleri Amaç İçin Gerekli Olan
          Süre Kadar Muhafaza Etme
        </p>{" "}
        <p className="text-justify  text-base sm:text-2xl">
          İşlenen kişisel veriler, ancak ilgili mevzuatta öngörülen veya
          işlendikleri amaç için gerekli olan süre kadar muhafaza edilmektedir.
          Bu kapsamda, ilgili mevzuatta verilerin saklanması için öngörülen bir
          süre varsa bu süreye uyulmakta; böyle bir süre yoksa veriler, ancak
          işlendikleri amaç için gerekli olan süre kadar muhafaza edilmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          4-KİŞİSEL VERİLERİN İŞLENME AMAÇLARI
        </h3>
        <p className="text-justify  text-base sm:text-2xl">
          Kişisel Veriler aşağıda sayılan amaçlarla sınırlı olmamak üzere
          işlenmektedir.
        </p>
        <br />
        <ul className="list-disc">
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde
              hukuki yükümlülüklerimizin yerine getirilmesini sağlamak,sözleşme
              hazırlanması, ifa edilmesi ve sonlandırılması sırasında ifa
              yükümlülüklerinin yerine getirilebilmesi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Şirket ana sözleşmesinde belirtilen ticari faaliyetlerin mevzuata
              uygun olarak yerine getirilmesi için ilgili birimler tarafından
              gerekli çalışmaların yapılması ve bu doğrultuda faaliyetlerin
              yürütülmesi,
            </p>
          </li>{" "}
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Sözleşme imzalanması, uygulanması ve sonlandırılması,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Kısa, orta, uzun vadeli ticari politikalarının tespiti,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Etkin bir müşteri hizmetinin sunulabilmesi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Hizmet ve tekliflerin sunulması,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi.’nin
              ticari güvenilirliğinin sağlanabilmesi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              İstek, talep ve şikayetlerin cevaplanarak çözümlenmesinin
              sağlanması,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Pazar araştırmaları ve istatistiksel çalışmalar yapılabilmesi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              İş ilişkisi içinde bulunan kişiler ile irtibat sağlanması,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Çalışanların Veri Sahibi bilgilerine erişim yetkilerinin
              planlanması ve icrası,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin
              takibi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Hukuk işlerinin takibi,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Kurumsal iletişim faaliyetlerinin planlanması ve icrası,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Verilerin doğru ve güncel olmasının sağlanması.
            </p>
          </li>
        </ul>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          5-KİŞİSEL VERİLERİN VE ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENME
          ŞARTLARI
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Kişisel verilerin işlenme koşulları 6698 Sayılı Kanun Metninde
          Düzenlenmiştir. Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi. tarafından kişisel veriler bu hükümlere göre işlenmektedir;
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -İlgili kişinin açık rızası varsa,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Kanunlarda açıkça öngörülmüşse,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan
          veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da
          bir başkasının hayatı veya beden bütünlüğünün korunması için
          zorunluysa,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekliyse,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
          zorunluysa,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -İlgili Kişinin kendisi tarafından alenileştirilmişse,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunluysa,
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunluysa,
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Kanunun 6.maddesine göre kişilerin ırkı, etnik kökeni,siyasi
          düşüncesi,felsefi inancı,dini,mezhebi,veya diğer inançları, kılık ve
          kıyafeti, dernek vakıf veya sendika üyeliği,sağlığı, cinsel hayatı,
          ceza mahkumiyeti,ve güvenlik tedbirleriyle ilgili verileri,ile
          biyometrik ve genetik verileri özel nitelikli kişisel verilerdir.
          <br /> <br />
          Özel nitelikli kişisel verilerin ilgilinin açık rızası olmaksızın
          işlenmesi yasaktır. Ancak sağlık ve cinsel hayat dışındaki kişisel
          veriler, kanunlarda öngörülen hallerde ilgili kişinin açık rızası
          olmaksızın işlenebilir.Sağlık ve cinsel hayata ilişkin kişisel verile
          ise ancak kamu sağlığının korunması,, koruyucu hekimlik, tıbbi teşhis,
          tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile
          finansmanının planlanması ve yönetimi amacıyla, sır saklama
          yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar
          tarafından ilgili kişinin açık rızası olmaksızın işlenebilir. Bu
          nedenle özel nitelikli kişisel verilerin işlenmesine Bamtaş İnşaat
          Taahhüt Maden Sanayi Ticaret Anonim Şirketi olarak gerekli özen
          gösterilmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          6-KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN ÖNLEMLER
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Kişisel verilerin ve özel nitelikli kişisel verilerin korunmasına
          yönelik olarak Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi olarak teknik ve idari tedbirler alınacak ve bu tedbirler
          sürekli olarak revize edilerek güncel duruma göre uyarlanacaktır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl font-bold">
          6.1.Teknik Tedbirler
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Teknolojinin imkanları doğrultusunda teknik önlemler alınmıştır ve
          düzenli olarak bu önlemlerin yeterliliği, işlevi kontrol edilmelidir.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Teknik konularda uzman personel istihdam edilmiş olup aynı zamanda bu
          konuda uzman kişilerden destek alınmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Güvenliği temin edecek yazılım ve donanımlar kullanılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -İşlenen kişisel verilere erişim sınırlandırılmıştır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Sızma (Penetrasyon) testleri ile bilişim sistemlerine yönelik risk,
          tehdit,zafiyet ve varsa açıklıklar ortaya çıkarılarak gerekli önlemler
          alınmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Bilgi güvenliği olay yönetimi ile gerçek zamanlı yapılan analizler
          sonucunda bilişim sistemlerinin sürekliliğini etkileyecek riskler ve
          tehditler sürekli olarak izlenmektedir.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Bilişim sistemlerine erişim ve kullanıcıların yetkilendirilmesi,
          erişim ve yetki matrisi ile kurumsal aktif dizin üzerinden güvenlik
          politikaları aracılığı ile yapılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Çevresel tehditlere karşı bilişim sistemleri güvenliğinin sağlanması
          için, donanımsal (sistem odasına sadece yetkili personelin girişini
          sağlayan erişim kontrol sistemi, 7/24çalışan izleme sistemi, yerel
          alan ağını oluşturan kenar anahtarların fiziksel güvenliğinin
          sağlanması, yangın söndürme sistemi, iklimlendirme sistemi vb.) ve
          yazılımsal (güvenlik duvarları, atak önleme sistemleri, ağ erişim
          kontrolü, zararlı yazılımları engelleyen sistemler vb.) önlemler
          alınmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Kişisel verilerin hukuka aykırı işlenmesini önlemeye yönelik riskler
          belirlenmekte, bu risklere uygun teknik tedbirlerin alınması
          sağlanmakta ve alınan tedbirlere yönelik teknik kontroller
          yapılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Kurum içerisinde erişim prosedürleri oluşturularak kişisel verilere
          erişim ile ilgili raporlama ve analiz çalışmaları yapılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Kişisel verilerin bulunduğu saklama alanlarına erişimler kayıt altına
          alınarak uygunsuz erişimler veya erişim denemeleri kontrol altında
          tutulmaktadır..
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Kişisel verilerin hukuka aykırı olarak başkaları tarafından elde
          edilmesi halinde bu durumu ilgili kişiye ve Kurula bildirmek için
          Kurum tarafından buna uygun bir sistem ve altyapı oluşturulmuştur.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Güvenlik açıkları takip edilerek uygun güvenlik yamaları yüklenmekte
          ve bilgi sistemleri güncel halde tutulmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Kişisel verilerin işlendiği elektronik ortamlarda güçlü parolalar
          kullanılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Kişisel verilerin işlendiği elektronik ortamlarda güvenli kayıt tutma
          (loglama) sistemleri kullanılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          - Kişisel verilerin güvenli olarak saklanmasını sağlayan veri
          yedekleme programları kullanılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Elektronik olan veya olmayan ortamlarda saklanan kişisel verilere
          erişim, erişim prensiplerine göre sınırlandırılmaktadır.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Özel nitelikli kişisel verilerin güvenliğine yönelik ayrı politika
          belirlenmiştir.
        </p>{" "}
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Elektronik olan veya olmayan ortamlarda saklanan kişisel verilere
          erişim, erişim prensiplerine göre sınırlandırılmaktadır.
        </p>{" "}
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Özel nitelikli kişisel veri işleme süreçlerinde yer alan çalışanlara
          yönelik özel nitelikli kişisel veri güvenliği konusunda eğitimler
          verilmiş, gizlilik sözleşmeleri yapılmış, verilere erişim yetkisine
          sahip kullanıcıların yetkileri tanımlanmıştır.
        </p>{" "}
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Özel nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya
          erişildiği elektronik ortamlar kriptografik yöntemler kullanılarak
          muhafaza edilmekte, kriptografik anahtarlar güvenli ortamlarda
          tutulmakta, tüm işlem kayıtları loglanmakta, ortamların güvenlik
          güncellemeleri sürekli takip edilmekte, gerekli güvenlik testlerinin
          düzenli olarak yaptırılıp, test sonuçlarının kayıt altına
          alınmaktadır.
        </p>{" "}
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Özel nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya
          erişildiği fiziksel ortamların yeterli güvenlik önlemleri alınmakta,
          fiziksel güvenliği sağlanarak yetkisiz giriş çıkışlar
          engellenmektedir.
        </p>{" "}
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Özel nitelikli kişisel veriler e-posta yoluyla aktarılması
          gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya KEP hesabı
          kullanılarak aktarılmaktadır. Taşınabilir bellek, CD, DVD gibi
          ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle
          şifrelenmekte ve kriptografik anahtar farklı ortamda tutulmaktadır.
          Farklı fiziksel ortamlardaki sunucular arasında aktarma
          gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP
          yöntemiyle veri aktarımı gerçekleştirilmektedir. Kağıt ortamı yoluyla
          aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz
          kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler
          alınmakta ve evrak “gizli” formatta gönderilmektedir.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl font-bold">
          6.2.İdari Tedbirler
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Çalışanlara, 6698 Sayılı Kanun ve ilgili diğer mevzuat hakkında
          eğitimler verilmektedir.
        </p>
        <p className="text-justify  text-base sm:text-2xl">
          -Yürütülen faaliyetlere ilişkin çalışanlara gizlilik sözleşmeleri
          imzalatılmaktadır.
        </p>
        <p className="text-justify text-base sm:text-2xl">
          -Güvenlik politika ve prosedürlerine uymayan çalışanlara yönelik
          uygulanacak disiplin prosedürü hazırlanmıştır.
        </p>
        <p className="text-justify text-base sm:text-2xl">
          -Kişisel veri işlemeye başlamadan önce Kurum tarafından, ilgili
          kişileri aydınlatma yükümlülüğü yerine getirilmektedir.
        </p>
        <p className="text-justify text-base sm:text-2xl">
          -Kişisel veri işleme envanteri hazırlanmıştır.
        </p>
        <p className="text-justify text-base sm:text-2xl">
          -Kurum içi periyodik ve rastgele denetimler yapılmaktadır.
        </p>
        <p className="text-justify text-base sm:text-2xl">
          -Çalışanlara yönelik bilgi güvenliği eğitimleri verilmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          7-KİŞİSEL VERİLERİN AKTARILMASI
        </h3>
        <br />
        <p className="text-justify text-base sm:text-2xl">
          Hukuka uygun olarak elde edilen Kişisel Veriler, veri işleme amaçları
          doğrultusunda, gerekli güvenlik önlemlerini alarak, Veri Sahibi’nin
          Kişisel Verileri’ni ve/veya Özel Nitelikli Kişisel Verileri’ni üçüncü
          kişilere aşağıdaki koşullarda aktarılabilmektedir.
        </p>
        <br />
        <ul className="list-disc">
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Veri Sahibi’nin açık rızası var ise,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Kanunlarda Kişisel Veri’nin aktarılacağına ilişkin açık bir
              düzenleme var ise,
            </p>
          </li>{" "}
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün
              korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık
              nedeniyle rızasını açıklayamayacak durumda ise veya rızasına
              hukuki geçerlilik tanınmıyorsa;
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin
              aktarılması gerekli ise,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi’nun
              hukuki yükümlülüğünü yerine getirmesi için kişisel veri aktarımı
              zorunlu ise,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya
              korunması için zorunlu ise,
            </p>
          </li>
          <li className="">
            <p className="text-justify  text-base sm:text-2xl ">
              Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, Bamtaş İnşaat Taah. Maden San Tic. A.Ş..’nun meşru
              Menfaatleri için kişisel veri aktarımı zorunlu ise
              aktarılabilmektedir.
            </p>
          </li>
        </ul>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          Özel nitelikli kişisel veriler ise;
          <br />
          ilgili kişinin açık rızası var ise,ilgili kişinin açık rızası yok
          ancak;
          <br /> <br />
          -İlgili kişinin sağlığı ve cinsel hayatı dışındaki özel nitelikli
          kişisel veriler(ırk,etnik köken, siyasi düşünce, felsefi inanç, din,
          mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da
          sendika üyeliği, ceza mahkumiyeti,ve güvenlik tedbirleriyle ilgili
          veriler ile biyometrik ve genetik veriler)kanunlarda öngörülen
          hallerde,
          <br /> <br />
          -İlgili kişinin sağlığına ve cinsel hayatına ilişkin özel nitelikli
          kişisel verileri ise ancak kamu sağlığının korunması, koruyucu
          hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi,
          sağlık hizmetleri ve finansmanın planlaması, ve yönetimi amacıyla, sır
          saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve
          kuruluşlar tarafından işlenebilir. Kişisel Veriler, Kanun’un ilgili
          maddelerine uygun olarak aşağıdaki kişi kategorilerine aktarılabilir;
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          8-KİŞİSEL VERİLERİN YURTDIŞINA AKTARILMASI
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          <span className="font-semibold">8.1.</span> Veri Sahibi’nin kişisel
          verilerini, açık rızası var ise veya, Veri Sahibi’nin açık rızası yok
          ise, aşağıdaki hallerden birinin varlığı durumunda, Kişisel veriler
          Yeterli korumaya sahip veya yeterli korumayı taahhüt eden veri
          sorumlusunun bulunduğu yabancı ülkelere aktarılabilecektir:
          <br />
          <br />
          -Kanunlarda Kişisel Veri’nin aktarılacağına ilişkin açık bir düzenleme
          var ise,
          <br />
          <br /> -Veri Sahibi’nin veya başkasının hayatı veya beden bütünlüğünün
          korunması için zorunlu ise ve Veri Sahibi fiili imkânsızlık nedeniyle
          rızasını açıklayamayacak durumda ise veya rızasına hukuki geçerlilik
          tanınmıyorsa;
          <br />
          <br /> -Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
          ilgili olmak kaydıyla sözleşmenin taraflarına ait kişisel verinin
          aktarılması gerekli ise,
          <br />
          <br /> - Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi.’nin hukuki yükümlülüğünü yerine getirmesi için kişisel veri
          aktarımı zorunlu ise,
          <br />
          <br /> -Kişisel Veriler, Veri Sahibi tarafından alenileştirilmiş ise,
          <br />
          <br /> -Kişisel Veri aktarımı bir hakkın tesisi, kullanılması veya
          korunması için zorunlu ise,
          <br />
          <br /> -Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek
          kaydıyla, Bamtaş'ın meşru menfaatleri için kişisel veri aktarımı
          zorunlu ise.
        </p>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          <span className="font-semibold">8.2.</span> Özel Nitelikli Kişisel
          Veriler aşağıdaki durumlarda yeterli korumaya sahip veya yeterli
          korumayı taahhüt eden veri sorumlusunun bulunduğu yabancı ülkelere
          aktarılabilmektedir.
          <br />
          <br />
          -Kişisel veri sahibinin açık rızası var ise,
          <br />
          <br /> -Kişisel veri sahibinin açık rızası olmasa bile; Veri
          Sahibi’nin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel
          verileri (ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep
          veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika
          üyeliği, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile
          biyometrik ve genetik verilerdir), kanunlarda öngörülen hallerde,
          <br />
          <br /> -Veri Sahibi’nin sağlığına ve cinsel hayatına ilişkin özel
          nitelikli kişisel verileri ise ancak kamu sağlığının korunması,
          koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin
          yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve
          yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
          veya yetkili kurum ve kuruluşlar tarafından işlenmesi kapsamında.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          9-KİŞİSEL VERİLERİN SAKLANMA SÜRELERİNE İLİŞKİN ESASLAR
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          Kişisel veriler, Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim
          Şirketi. tarafından ilgili mevzuatta öngörülen süreler boyunca
          saklanmaktadır. Kişisel verilerin ne kadar süre boyunca saklanması
          gerektiğine ilişkin mevzuatta bir süre düzenlenmemişse, kişisel
          veriler o veriyi işlerken yürütülen faaliyet ile bağlantılı olarak
          ticari teamüller doğrultusunda işlenmesini gerektiren süre kadar
          işlenmekte daha sonra silinmekte, yok edilmekte veya anonim hale
          getirilmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          10-KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİM HALE
          GETİRİLMESİ
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          Kişisel Veriler, Kanunu’nun 7. Maddesinde ve Türk Ceza Kanunu’nun 138.
          maddesinde ve düzenlendiği üzere ilgili kanun hükümlerine uygun olarak
          işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
          kalkması halinde, re’sen veya kişisel veri sahibinin talebi üzerine,
          silinir, yok edilir veya anonim hâle getirilir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          11-YÜKÜMLÜLÜKLER VE HAKLAR
        </h3>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          11.1.AYDINLATMA VE BİLGİLENDİRME YÜKÜMLÜLÜĞÜ
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          Kanun’un 10. maddesine uygun olarak, kişisel verilerin elde edilmesi
          sırasında Veri Sahipleri aydınlatılmaktadır. Bu kapsamda kişisel
          veriler işlenmeden önce ilgili kişilere Kanunun ilgili maddesi
          uyarınca aydınlatma-bilgilendirme yapılmaktadır. Şirketin internet
          sayfasında Aydınlatma Metni yayınlanmıştır.Şirket binalarında muhtelif
          yerlerde Aydınlatma metinleri asılmıştır. Her veri işlenmesi öncesinde
          verisi işlenecek kişiye ayrıca sözlü ve yazılı olarak Aydınlatma
          Bilgilendirmesi yapılmaktadır.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          11.2.VERİ SAHİBİ’NİN HAKLARI
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl ">
          Veri Sahipleri, aşağıdaki haklara sahiptirler:
          <br />
          -kişisel verilerini işleyip işlemediğini öğrenme,
          <br />
          -Eğer Kişisel Veriler işleniyorsa, bu veri işleme faaliyeti hakkında
          bilgi talep etme,
          <br />
          -Eğer Kişisel Veriler işleniyorsa, kişisel veri işleme faaliyetinin
          amacını ve işlenme amacına uygun kullanılıp kullanmadığını öğrenme,
          <br />
          -Eğer Kişisel Veriler yurtiçinde veya yurtdışında üçüncü kişilere
          aktarılıyorsa, bu üçüncü kişiler hakkında bilgi talep etme,
          <br />
          -Kişisel Veriler’in eksik veya yanlış işlenmiş olması halinde,
          bunların düzeltilmesini talep etme,
          <br />
          -Kişisel Veriler’in eksik veya yanlış işlenmesi durumunda, Kişisel
          Veriler’in aktarıldığı üçüncü kişilere bu durumun bildirilmesini talep
          etme,
          <br />
          -Kişisel Veriler’in Kanun ve ilgili diğer kanun hükümlerine uygun
          olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
          ortadan kalkması hâlinde Kişisel Veriler’in silinmesini, yok
          edilmesini veya anonim hale getirilmesini isteme.
          <br />
          -Kişisel Veriler’in işlenmesini gerektiren sebepler ortadan kalktıysa,
          bu durumun Kişisel Veriler’in aktarıldığı üçüncü kişilere
          bildirilmesini talep etme,
          <br />
          -İşlenen Kişisel Veriler’in münhasıran otomatik sistemler vasıtasıyla
          analiz edilmesi ve bu analiz neticesinde ilgili kişinin (Veri Sahibi)
          aleyhine bir sonuç doğduğunu düşündüğü sonuçların ortaya çıkması
          halinde, bu sonuca itiraz etme.
          <br />
          -Kişisel Veriler’in kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğranması halinde zararın giderilmesini talep etme.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Politika;
