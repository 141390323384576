import React from "react";
import FaceBook from "../assets/social-logo/FaceBook.svg";
import Instagram from "../assets/social-logo/Instagram.svg";
import Linkedin from "../assets/social-logo/Linkedin.svg";
import Twitter from "../assets/social-logo/Twitter.svg";
import BamtasWhite from "../assets/bamtas-white.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const toPage = (section) => {
    navigate("/" + section);
    window.scrollTo(0, 0);
  };
  return (
    <footer id="footer" className="w-full bg-black md:h-96">
      <div
        id="footer-container"
        className="justify-between
        h-full
        sm:px-4
        mx-auto
        lg:max-w-7xl
        md:items-center
        md:flex
        md:px-8"
      >
        <div id="left" className="flex-grow h-full flex flex-col max-w-xl ">
          <div className="container w-full h-full flex flex-col justify-around">
            <img
              alt="bamtas logo beyaz"
              src={BamtasWhite}
              className="h-10 object-contain place-self-start cursor-pointer mt-5"
              onClick={() => {
                toPage("");
              }}
            />

            <p className="text-white font-normal text-sm">
            İvedikköy Mahallesi Anadolu Bulvarı TR İnvest Corner 2 İş Merkezi No:151/22-20<br/> Yenimahalle/ANKARA
             
            </p>

            <p className="text-white font-normal text-sm">444 82 71</p>
            <p className="text-white font-normal text-sm mb-5">
              info@bamtas.com
            </p>
            <div id="social-box" className="flex gap-2 mb-5">
              <img
                alt="facebook link"
                src={FaceBook}
                className="cursor-pointer"
              ></img>

              <img
                alt="twitter link"
                src={Twitter}
                className="cursor-pointer"
              ></img>
              <img
                alt="Linkedin link"
                src={Linkedin}
                className="cursor-pointer"
              ></img>
              <a
                href="https://www.instagram.com/bamtasinsaat/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt="Instagram Link"
                  src={Instagram}
                  className="cursor-pointer"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div
          id="right"
          className="flex-grow-[3] flex justify-between sm:flex-nowrap sm:gap-0 gap-y-5 flex-wrap "
        >
          <div className="flex flex-col gap-6 w-[50%]">
            <p className="font-semibold text-white cursor-pointer">
              Hakkımızda
            </p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("baskanin-mesaji");
              }}
            >
              Başkan'ın Mesajı
            </p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("misyonumuz-vizyonumuz");
              }}
            >
              Misyonumuz ve Vizyonumuz
            </p>

            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("referans");
              }}
            >
              Grup Şirketler ve Proje Ortakları
            </p>
          </div>
          <div className="flex flex-col gap-6 w-[50%]">
            <p className="font-semibold text-white cursor-pointer">
              Faaliyet Alanlarımız
            </p>

            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("altyapi");
              }}
            >
              Altyapı
            </p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("insaat");
              }}
            >
              Üstyapı
            </p>
          </div>
          <div className="flex flex-col gap-6 w-[50%]">
            <p className="font-semibold text-white cursor-pointer">Kurumsal</p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("kurumsal-yonetim");
              }}
            >
              Kurumsal Yönetim ve İlkeleri
            </p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("is-guvenligi");
              }}
            >
              İş Güvenliği
            </p>
            {/* <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("sertifikalar");
              }}
            >
              Sertifikalar ve Belgeler
            </p> */}
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("kvkk");
              }}
            >
              Kişisel Verilerin Korunma Politikası
            </p>
            <p
              className="text-white font-normal text-xs cursor-pointer"
              onClick={() => {
                toPage("basindabiz");
              }}
            >
              Basında Biz
            </p>
          </div>
          <div className="flex flex-col gap-6 w-[50%]">
            <p
              className="font-semibold text-white cursor-pointer"
              onClick={() => {
                toPage("iletisim");
              }}
            >
              İletişim
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
