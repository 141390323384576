import React, { useState } from "react";
import PropTypes from "prop-types";
import { ProjectCarousel } from "./ProjectCarousel";

export const Project = ({ projectInfo }) => {
  const [selectedImg, setSelectedImg] = useState(projectInfo.images[0]);
  return (
    <div>
      <div className="flex md:flex-row flex-col-reverse">
        <div className="flex-1 p-2">
          <img src={selectedImg} alt="displayed project" className="hidden sm:block" />
        </div>
        <div className="flex-1 p-4">
          <p className="text-black font-bold sm:text-3xl">{projectInfo.name}</p>
          <p className="text-sm sm:text-base">
            {projectInfo.city}, {projectInfo.country}
          </p>
          <br></br>
          <p>{projectInfo.location}</p>
          <p className="text-sm sm:text-base">{projectInfo.description}</p>
        </div>
      </div>
      <div>
        <ProjectCarousel
          imgArray={projectInfo.images}
          onImgSelection={setSelectedImg}
        />
      </div>
    </div>
  );
};

Project.propTypes = {
  projectInfo: PropTypes.object.isRequired,
};

export default Project;
