import React from "react";
import PromoBg from "../../assets/promo-bg.png";

const Promo = () => {
  return (
    <div className="relative">
      <img
        src={PromoBg}
        alt="güzelyalı projesi"
        className="w-full -z-10  aspect-auto object-cover md:h-[calc(100vh - 384px)] min-h-[365px]"
      ></img>
      <div
        id="hero-section-heading"
        className=" absolute top-2/4 sm:left-1/2 left-1/2 transform -translate-x-1/3 -translate-y-1/2 lg:container  text-white lg:max-w-7xl md:px-4"
      >
        <p className="text-xl lg:text-6xl text-end animate-left-to-right">
          Kaliteli, Yaşanabilir ve Çevreye Saygılı Projeler <br></br>
          <span className="text-base lg:text-3xl font-elyazisi">
            Bulunduğu bölgeye modern, estetik yaşam alanları ile değer katar.
          </span>
        </p>
      </div>
    </div>
  );
};

export default Promo;
