import React from "react";
import PropTypes from "prop-types";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export const ProjectCarousel = ({ imgArray, onImgSelection }) => {
  const onClick = (event, param) => {
    onImgSelection(param);
  };
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4 },
  };
  //   const items = [
  //     <div className="item" data-value="1">
  //       1
  //     </div>,
  //     <div className="item" data-value="2">
  //       2
  //     </div>,
  //     <div className="item" data-value="3">
  //       3
  //     </div>,
  //     <div className="item" data-value="4">
  //       4
  //     </div>,
  //     <div className="item" data-value="5">
  //       5
  //     </div>,
  //   ];
  const items = imgArray.map((value, index) => (
    <div
      className="item p-1 sm:p-2"
      key={index}
      onClick={(event) => onClick(event, value)}
    >
      {" "}
      <img src={value} alt="" role="presentation" className="max-h-44 m-auto" />
    </div>
  ));
  //   console.log(items);
  return (
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      controlsStrategy="alternate"
    />
  );
};

ProjectCarousel.propTypes = {
  imgArray: PropTypes.array.isRequired,
  onImgSelection: PropTypes.func.isRequired,
};

export default ProjectCarousel;
