import React from "react";
import { PropTypes } from "prop-types";
import "./customProjectCard.css";
import { useNavigate } from "react-router-dom";

const ProjectCard = (props) => {
  const navigate = useNavigate();
  const toPage = (route) => {
    navigate("/" + route);
    window.scrollTo(0, 0);
  };
  
  return (
    <div
      id="project-card-wrapper"
      className="relative h-[465px] w-[360px] projectCard"
      onClick={() => {
        toPage(props.category);
      }}
    >
      <img
        src={props.image}
        className="absolute aspect-auto object-cover -z-10 h-full"
        alt="proje görseli"
      />
      <p className="font-semibold text-2xl text-white pt-8 pl-6">
        {props.name}
      </p>
      <p className="font-semibold text-sm text-white mt-3 pl-6">
        {props.city + ","} {props.country}
      </p>
      <hr className="m-auto mt-3" />
      <p className="font-normal text-lg text-white pt-8 pl-6">
        {props.summary}
      </p>
    </div>
  );
};

ProjectCard.propTypes = {
  name: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  summary: PropTypes.string,
};
export default ProjectCard;
