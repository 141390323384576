import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import insaatBg from "../assets/hero-background.png";
import insaatIcon from "../assets/insaat-icon.png";
import Project from "../components/Project/Project";
import projectsData from "../ProjectData/projects.json";
//üstyapı
const Insaat = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={insaatBg}
          alt="ınsaat arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            ÜSTYAPI
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 sm:flex">
        <div className="flex-1">
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 transition-all"></div>
            <h3 className=" sm:text-2xl md:text-4xl pb-3 sm:pb-0 pl-5 text-start  text-black font-bold">
              İnşaat Uygulamaları
            </h3>
          </div>
          <p className="pt-5 text-justify text-sm sm:text-lg">
            Projelendirmeden anahtar teslimine ve işletme destek hizmetlerine
            kadar uzanan sürecin her aşamasında, uluslararası standartlarda
            hizmet sunan Bamtaş İnşaat, istikrarlı bir biçimde artış gösteren iş
            hacmiyle sektörünün önemli bölgesel oyuncularından biri haline
            gelmiştir.
          </p>
        </div>
        <div className="flex items-center justify-center flex-1">
          <div>
            <img
              src={insaatIcon}
              alt="insaat ikon"
              className={"aspect-auto hidden sm:block"}
            ></img>
          </div>
        </div>
      </div>
      <div className="container m-auto flex flex-col sm:flex-row">
        <div className="flex items-center justify-center flex-1">
          <div className="p-4">
            <img
              alt="proje görseli"
              src={projectsData[0].images[0]}
              className={"aspect-auto sm:block"}
            ></img>
          </div>
        </div>
        <div className="flex-1 container pl-7 flex flex-col justify-around sm:items-center ">
          <div className="flex h-16 sm:items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700"></div>
            <div>
              <h3 className=" sm:text-xl md:text-2xl pb-3 sm:pb-0 pl-5 text-start  text-red-700 font-bold">
                Konut
              </h3>
            </div>
          </div>
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700"></div>
            <div>
              <h3 className=" sm:text-xl md:text-2xl pb-3 sm:pb-0 pl-5 text-start  text-red-700 font-bold">
                İşyeri & Ofis
              </h3>
            </div>
          </div>
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700"></div>
            <div>
              <h3 className=" sm:text-xl md:text-2xl pb-3 sm:pb-0 pl-5 text-start  text-red-700 font-bold">
                Alışveriş Merkezi
              </h3>
            </div>
          </div>
          <div className="flex h-16 items-center">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700"></div>
            <div>
              <h3 className=" sm:text-xl md:text-2xl pb-3 sm:pb-0 pl-5 text-start  text-red-700 font-bold">
                Havaalanları
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h5 className="container text-red-700 sm:text-2xl font-bold">
          Projeler
        </h5>
        {projectsData.map((e, index) => {
          if (e.category === "insaat") {
            return (
              <div className="border-b-2 border-red-700 mb-8">
                <Project projectInfo={e} />
              </div>
            );
          } else return null;
        })}
      </div>
      <Footer />
    </>
  );
};

export default Insaat;
