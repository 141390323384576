import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import kvkkImg from "../assets/kvkk.png";
const Kvkk = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kvkkImg}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold">
            KİŞİSEL VERİLERİN KORUNUMU KANUNU
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold animate-left-to-right ">KVKK</h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          <span className="font-bold">
            I.Kişisel Verilerin Korunmasının Önemi
          </span>
          <br />
          <br /> Kişisel verilerin korunması, Anayasal bir hak olup,
          Şirketimizin öncelikleri kapsamında yer almaktadır. Nitekim bu amaçla,
          Şirketimizde devamlı olarak güncellenen bir sistem kurulması
          amaçlanmış ve işbu politika oluşturulmuştur. 6698 sayılı Kişisel
          Verilerin Korunması Kanunu kapsamında, Veri Sorumlusu sıfatıyla,
          BAMTAŞ İNŞAAT ANONİM ŞİRKETİ’nin , genel aydınlatma
          yükümlülüğünü yerine getirmek ve Şirketimiz kişisel veri işleme
          kurallarının temel esaslarını belirlemek üzere işbu Politika
          yapılmakta ve bu kapsamda müşterilerimizin, potansiyel
          müşterilerimizin, çalışanlarımızın, çalışan adaylarımızın, stajyer ve
          öğrencilerimizin, tedarikçi/alt işveren çalışanlarının ve
          yetkililerinin, şirket hissedarlarımızın ve şirket ortaklarımızın,
          ziyaretçilerimizin ve diğer verisini işlediğimiz üçüncü kişilerin
          kişisel verilerinin korunması konusundaki temel esaslar
          düzenlenmektedir. Bu Politika’da belirtilen konuların uygulanmasına
          yönelik olarak Şirket içerisinde gerekli prosedürler düzenlenmekte,
          kişi kategorilerine özel Kişisel Veri İşleme Envanteri ile uyumlu
          aydınlatma metinleri oluşturulmakta, kişisel verilere erişimi olan
          Şirket çalışanları ve üçüncü taraflarla kişisel verilerin korunması ve
          gizlilik sözleşmeleri yapılmakta, görev tanımları revize edilmekte,
          kişisel verilerin korunması için BAMTAŞ İNŞAAT A.Ş. tarafından
          gereken idari ve teknik tedbirler alınmakta, bu kapsamda gerekli
          denetimler yapılmakta veya yaptırılmaktadır. Kişisel Verilerin
          Korunması konusu üst yönetim tarafından da sahiplenilmekte, bu konuda
          özel bir Komite oluşturulması suretiyle kişisel verilerin korunması
          süreçleri yönetilmektedir. <br />
          <br />
          <span className="font-bold">II. Politikanın Amacı</span>
          <br />
          <br /> Bu Politikanın temel amacı, BAMTAŞ tarafından hukuka uygun bir
          biçimde yürütülen kişisel veri işleme faaliyeti ve kişisel verilerin
          korunmasına yönelik esasları ortaya koymak, bu kapsamda kişisel
          verileri şirketimiz tarafından işlenen kişileri aydınlatarak ve
          bilgilendirilerek şeffaflığı sağlamaktır.
          <br />
          <br />
          <span className="font-bold">III. Kapsam</span> <br />
          Bu Politika; “müşterilerimiz, potansiyel müşterilerimiz,
          çalışanlarımız, çalışan adaylarımız, stajyer ve öğrencilerimiz,
          tedarikçi/alt işveren çalışanları ve yetkilileri, şirket
          hissedarlarımız ve şirket ortaklarımız, ziyaretçilerimiz ve diğer
          verisini işlediğimiz üçüncü kişiler” başlıkları altında kategorize
          ettiğimiz kişilerin otomatik olan ya da herhangi bir veri kayıt
          sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
          işlediğimiz tüm kişisel verilerine ilişkindir. <br />
          <br />
          <span className="font-bold">
            IV. Politikanın ve İlgili Mevzuatın Uygulanması
          </span>
          <br />
          <br />
          Kişisel verilerin işlenmesi ve korunması konusunda yürürlükte bulunan
          ilgili kanuni düzenlemeler öncelikle uygulama alanı bulacaktır.
          Yürürlükte bulunan mevzuat ve Politika arasında uyumsuzluk bulunması
          durumunda, Şirketimiz yürürlükteki mevzuatın uygulama alanı bulacağını
          kabul etmektedir. da, Şirketimizin hem kurumsal internet sitesinde yer
          almaktadır.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Kvkk;
