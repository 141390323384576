import React from "react";
import missionBackground from "../assets/hero-background.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import yapiIcon from "../assets/references/yapi-yapi.svg";
import abbIcon from "../assets/references/abb.svg";
import aslIcon from "../assets/references/asl.svg";
import cengizIcon from "../assets/references/cengiz-insaat.svg";
import sogutIcon from "../assets/references/sogut.svg";
import uguralIcon from "../assets/references/ugural.svg";
import ziverIcon from "../assets/references/ziver.svg";
import ozkardeslerIcon from "../assets/references/ozkardesler.svg";

const Grupsirketler = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          alt="arkaplan"
          id="hero-background"
          src={missionBackground}
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute w-auto m-auto text-white bottom-1/4 sm:left-72">
          <h1 className="text-5xl font-bold sm:text-7xl sm:pl-12 animate-left-to-right">
            GRUP ŞİRKETLER VE PROJE ORTAKLARI
          </h1>
        </div>
      </div>
      <div className="container px-4 pb-12 m-auto mt-12">
        <h3 className="pb-4 text-lg font-bold text-red-700 sm:text-3xl w-ful">
          Proje Ortakları
        </h3>
        <div className="flex flex-col justify-center align-center sm:flex-row sm:justify-between">
          <div className="block m-auto w-36">
            <img src={yapiIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Yapi & Yapi Insaat
            </p>
          </div>

          <div className="block m-auto w-36">
            <img src={abbIcon}></img>
            <p className="pt-4 text-lg font-normal text-center ">ABB</p>
          </div>
          <div className="block m-auto w-36">
            <img src={aslIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">ASL İnşaat</p>
          </div>
          <div className="block m-auto w-36">
            <img src={cengizIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Cengiz İnşaat
            </p>
          </div>
          <div className="block m-auto w-36">
            <img src={sogutIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">Söğüt İnşaat</p>
          </div>
          {/* <div className="block m-auto w-36">
            <img src={uguralIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Uğural İnşaat
            </p>
          </div> */}
          <div className="block m-auto w-36">
            <img src={ziverIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Ziver Holding
            </p>
          </div>
        </div>
        {/* <h3 className="pb-4 text-lg font-bold text-red-700 sm:text-3xl w-ful">
          Grup Şirketler
        </h3> */}
        {/* <div className="flex flex-col justify-center align-center sm:flex-row sm:justify-between">
          <div className="block m-auto w-36">
            <img src={uguralIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Uğural İnşaat
            </p>
          </div>
          <div className="block m-auto w-36">
            <img src={ozkardeslerIcon}></img>
            <p className="pt-4 text-lg font-normal text-center">
              Özkardeşler İnşaat
            </p>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default Grupsirketler;
