import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import kurumsalYonetim from "../assets/kurumsal-yonetim.png";
import milliyetIcon from "../assets/basindabiz/milliyet.svg";
import sozcuIcon from "../assets/basindabiz/sozcu.svg";
import hurriyetIcon from "../assets/basindabiz/Hurriyet.png";
import utikadIcon from "../assets/basindabiz/utikad.png";
import rizevalilikIcon from "../assets/basindabiz/rize-valilik.png";
import sondakikaIcon from '../assets/basindabiz/sondakika.png'

const Basindabiz = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kurumsalYonetim}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            BASINDA BIZ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4 flex flex-wrap gap-y-4 sm:gap-4 justify-center">
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="">
            <img class="rounded-t-lg w-full p-4" src={milliyetIcon} alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                Karadeniz’e dolgu lojistik liman inşaatı başladı
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Rize’de, Karadeniz’e dolguyla kazanılacak alanda inşa edilecek
              İyidere Lojistik Liman Projesi inşaatı başladı. 2023'te
              tamamlanması hedeflenen projeyle, bölge ticaretine yılda 200
              milyon dolar, yan istihdama ise 450 milyon dolar katkı sağlanacak.
            </p>
            <a
              href="https://www.milliyet.com.tr/ekonomi/karadenize-dolgu-lojistik-liman-insaati-basladi-6631859"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="https://www.sozcu.com.tr/2022/yazarlar/cigdem-toker/iyidere-lojistik-limani-ve-millet-bahcesi-7027553/">
            <img
              class="rounded-t-lg w-full p-4 bg-red-700"
              src={sozcuIcon}
              alt=""
            />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                İyidere Lojistik Limanı ve Millet Bahçesi
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Rize İyidere Lojistik Limanı, dış ticareti geliştirmek için
              planlanmış bir yatırım.
            </p>
            <a
              href="https://www.milliyet.com.tr/ekonomi/karadenize-dolgu-lojistik-liman-insaati-basladi-6631859"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="https://www.sozcu.com.tr/2022/yazarlar/cigdem-toker/iyidere-lojistik-limani-ve-millet-bahcesi-7027553/">
            <img
              class="rounded-t-lg w-full p-4 bg-red-700"
              src={hurriyetIcon}
              alt=""
            />
          </a>
          <div class="p-5">
            <a href="https://www.hurriyet.com.tr/ekonomi/denize-dolgu-rize-artvin-havalimani-yarin-aciliyor-42061713">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                Denize dolgu Rize-Artvin Havalimanı yarın açılıyor
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Dünyada sadece 5 tane olan ve bunun ikisi Türkiye'de olan denize
              dolgu yapılarak inşa edilen Rize-Artvin Havalimanı yarın açılıyor.
              Dev havalimanının açılışını Cumhurbaşkanı Recep Tayyip Erdoğan ve
              Azerbaycan Cumhurbaşkanı İlham Aliyev gerçekleştirecek.{" "}
            </p>
            <a
              href="https://www.hurriyet.com.tr/ekonomi/denize-dolgu-rize-artvin-havalimani-yarin-aciliyor-42061713"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="https://www.utikad.org.tr/Detay/Sektor-Haberleri/25133/rize-artvin-havalimani-icin-denize-20-5-milyon-ton-dolgu-yapildi">
            <img class="rounded-t-lg w-full p-4" src={utikadIcon} alt="" />
          </a>
          <div class="p-5">
            <a href="https://www.utikad.org.tr/Detay/Sektor-Haberleri/25133/rize-artvin-havalimani-icin-denize-20-5-milyon-ton-dolgu-yapildi">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                RİZE-ARTVİN HAVALİMANI İÇİN DENİZE 20,5 MİLYON TON DOLGU YAPILDI
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Temeli 2 yıl önce atılan Türkiye'nin deniz dolgusuna inşa edilecek
              ikinci havalimanı olma özelliğine sahip olacak olan Rize-Artvin
              Havalimanı için aralıksız malzeme taşınan alanda denize 20,5
              milyon ton taş döküldüğü bildirildi.
            </p>
            <a
              href="https://www.utikad.org.tr/Detay/Sektor-Haberleri/25133/rize-artvin-havalimani-icin-denize-20-5-milyon-ton-dolgu-yapildi"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="http://www.rize.gov.tr/rize-iyidere-lojistik-limani-rizeye-buyuk-deger-katacak">
            <img class="rounded-t-lg w-full p-4" src={rizevalilikIcon} alt="" />
          </a>
          <div class="p-5">
            <a href="http://www.rize.gov.tr/rize-iyidere-lojistik-limani-rizeye-buyuk-deger-katacak">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                Rize İyidere Lojistik Limanı Rize’ye Büyük Değer Katacak
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              İkizdere’de gerçekleştirilen toplantıda açıklamalarda bulunun AYGM
              Genel Müdürü Eyigün, Ulaştırma ve Altyapı Bakanlığı Altyapı
              Yatırımları Genel Müdürlüğü (AYGM) tarafından 16 Temmuz 2020
              tarihinde ihalesi yapılan “Rize İyidere Lojistik Limanı”
              projesinin Rize’ye çok önemli kazanımlar öngörülerek tasarlanarak
              ve hayata geçirilen bir proje olduğunu vurguladı.
            </p>
            <a
              href="http://www.rize.gov.tr/rize-iyidere-lojistik-limani-rizeye-buyuk-deger-katacak"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  min-w-[350px]">
          <a href="https://www.sondakika.com/haber/haber-rize-iyidere-lojistik-limani-icin-calismalar-14910976/">
            <img class="rounded-t-lg w-full p-4 bg-black" src={sondakikaIcon} alt="" />
          </a>
          <div class="p-5">
            <a href="https://www.sondakika.com/haber/haber-rize-iyidere-lojistik-limani-icin-calismalar-14910976/">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                İyidere Lojistik Limanı için çalışmalar sürüyor
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Rize'de yaklaşık 20 milyon ton taş kullanılıp deniz doldurularak
              yapılacak İyidere Lojistik Limanı için çalışmalar sürüyor.
            </p>
            <a
              href="https://www.sondakika.com/haber/haber-rize-iyidere-lojistik-limani-icin-calismalar-14910976/"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Haberi Oku
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Basindabiz;
