import React from "react";
import ProjectCard from "./ProjectCard";
import data from "../../../ProjectData/projects.json";

const ProjectSection = () => {
  return (
    <div id="projects-wrapper" className="mb-4 sm:pl-4 sm:pr-4 m-auto">
      <h5 className="text-[#E02F32] font-bold text-2xl md:mb-12 md:mt-12 ">
        Projeler
      </h5>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 m-auto place-items-center gap-2">
        {data.map((e, index) => {
          if (index > 3) {
            return null;
          }
          return (
            <ProjectCard
              key={e.name}
              name={e.name}
              city={e.city}
              country={e.country}
              summary={e.summary}
              image={e.images[0]}
              category={e.category}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectSection;
