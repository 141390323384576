import React from "react";
import { useNavigate } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/styles.css";
import "../home/ProjectsSection/customProjectCard.css";
import styles from "./HeroSection.css";

const HeroSection = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const navigate = useNavigate();
  const toPage = (route) => {
    navigate("/" + route);
  };
  return (
    <div className="relative" id="hero-section-wrapper">
      <AutoplaySlider
        cssModule={styles.awssld__Container}
        play={true}
        interval={6000}
        animation="fallAnimation"
        className="w-full h-screen aspect-auto object-cover z-[20_!important]"
      >
        <div className="w-full h-full">
          <img
            src="assets/hero-background.png"
            className="w-full h-screen animate-opacity-change"
          ></img>
        </div>
        <div className="w-full h-full">
          <img
            src="assets/asfalt/2.png"
            className="w-full h-screen animate-opacity-change"
          ></img>
        </div>
        <div className="w-full h-full">
          <img
            src="assets/asfalt/1.png"
            className="w-full h-screen animate-opacity-change"
          ></img>
        </div>

        <div className="w-full h-full">
          <img
            src="assets/asfalt/3.png"
            className="w-full h-screen animate-opacity-change"
          ></img>
        </div>
      </AutoplaySlider>
      <div
        id="hero-section-heading"
        className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 container  text-white lg:max-w-7xl px-4 md:px-8 animate-opacity-change z-20"
      >
        <h1 className="font-bold text-5xl lg:text-7xl mt-4 sm:mt-0 animate-fade-in-down ">
          BAMTAŞ &nbsp;
          <span className="text-red-700 ">İNŞAAT&nbsp;</span>
          <br />
        </h1>
        <br />
        <p className="font-normal md:text-3xl max-w-4xl  mt-4 sm:mt-0 -z-10 animate-fade-in-down ">
          Yarım asırlık geçmişten gelen bilgi, deneyim ve tecrübe ile yenilikçi
          yaklaşım.
        </p>
        <div
          id="section-tab"
          className="flex gap-6 w-full justify-center sm:hidden  mt-8 sm:mt-0 "
        >
          <div
            id="section-tab__option"
            className="flex group h-16 cursor-pointer "
          >
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 group-hover:h-14 transition-all mt-2  "></div>
            <button
              className="text-white text-center font-medium sm:text-4xl pb-3 sm:pb-0 pl-5 transition-all group-hover:scale-110 "
              onClick={() => {
                toPage("insaat");
              }}
            >
              Üstyapı
            </button>
          </div>

          <div id="section-tab__option" className="flex group h-16 ;">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 group-hover:h-14 transition-all mt-2"></div>
            <button
              className="text-white font-medium sm:text-4xl pb-3 sm:pb-0 pl-5 transition-all group-hover:scale-110"
              onClick={() => {
                toPage("altyapi");
              }}
            >
              Altyapı
            </button>
          </div>
        </div>
      </div>
      <div className="sm:absolute  text-white  sm:bottom-44 sm:right-32  container sm:flex hidden z-20">
        <div id="section-tab" className="flex gap-6 w-full justify-end">
          <div
            id="section-tab__option"
            className="flex group h-16 cursor-pointer "
          >
            <div className="sm:w-2 sm:h-12 bg-red-700 group-hover:h-16 transition-all mt-2"></div>
            <button
              className="text-white text-center font-medium sm:text-4xl pb-3 sm:pb-0 pl-5 transition-all group-hover:scale-110"
              onClick={() => {
                toPage("insaat");
              }}
            >
              Üstyapı
            </button>
          </div>
          <div id="section-tab__option" className="flex group h-16 ;"></div>
          <div id="section-tab__option" className="flex group h-16 ;">
            <div className="w-2 h-8 sm:w-2 sm:h-12 bg-red-700 group-hover:h-16 transition-all mt-2"></div>
            <button
              className="text-white font-medium sm:text-4xl pb-3 sm:pb-0 pl-5 transition-all group-hover:scale-110"
              onClick={() => {
                toPage("altyapi");
              }}
            >
              Altyapı
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
