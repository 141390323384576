import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import kurumsalYonetim from "../assets/kurumsal-yonetim.png";
const KurumsalYonetim = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kurumsalYonetim}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            KURUMSAL YÖNETİM VE İLKELERİ
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          Kurumsal Yönetim
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Şirketimizin Türkiye Ticaret Sicil Gazetesinde yayınlanan Ana Türkiye
          Sözleşmesinde yapılacak her türlü değişiklik düzenleyici makamların
          onayına tabi olup takiben değişiklikler Ticaret Sicil Gazetesi’nde ve
          kurumsal internet sitesinde yayınlanarak değişikliklerin kamuya
          duyurulması sağlanmaktadır. Kısaca, şeffaflığı sağlayan açıklamalar;
          Ticaret Sicili, basın bültenleri ve duyuruları, Şirketin kurumsal
          internet sitesi, denetlenmiş finansal tabloları içeren yıllık faaliyet
          raporunun yayınlanması yolları ile sağlanmaktadır. Pay sahipliği
          yapısı ve pay sahiplerinin isimleri ile payların adetleri ve
          yüzdeleri, Şirketin kurumsal internet sitesinde yayınlanmaktadır.
          Şirketimizin Yönetim Kurulu tarafından belirlenen misyonu ve vizyonu
          da, Şirketimizin hem kurumsal internet sitesinde yer almaktadır.
        </p>
        <br></br>
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          Amaç ve Kapsam
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Kurumsal yönetim tasarımının amacı, yukarıda açıklanan hususlara ek
          olarak, kurumsal yönetim politikasının oluşturulmasıdır. Bu amaçla,
          öncelikli olarak, Kurumsal Yönetim Komitesi tarafından Kurumsal
          Yönetim Rehberi çalışmaları devam etmektedir. Söz konusu Rehber ile
          yapılacak düzenlemeler ile Yönetim Kurulu, Yürütme Kurulu, Komiteler
          ile YK ve Yürütme Kurulunun çalışma sisteminin etkinliği, kurumsal
          yönetim değerleri ile mevzuat ve düzenlenmelere uyumu
          hedeflenmektedir. Kurumsal Yönetim Rehberi ile bu seviyelerdeki
          oluşumlarda, işleyiş usulleri, amaçlar, yetkiler, sorumluluklar, yetki
          devir ve sınırları ile limitler tanımlanmıştır. Düzenlemelerde ana
          unsur, şeffaflığı sağlayarak, Şirket içindeki Kurumsal Yönetimin
          güçlenmesine destek vermek ve her seviyede Şirket çalışanına ve üst
          yönetime yol göstermektir.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default KurumsalYonetim;
