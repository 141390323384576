import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import kurumsalYonetim from "../assets/kurumsal-yonetim.png";
const InternetAydinlatma = () => {
  return (
    <>
      <Navbar />
      <div className="relative" id="hero-section-wrapper">
        <img
          id="hero-background"
          src={kurumsalYonetim}
          alt="kurumsal yonetim arkaplan"
          className="w-full h-96 sm:h-[450px] -z-10 aspect-auto object-cover"
        ></img>
        <div className="container absolute bottom-1/4 sm:left-72 w-auto text-white m-auto">
          <h1 className="text-5xl sm:text-7xl sm:pl-12 font-bold animate-left-to-right">
            İnternet Aydinlatma Metni
          </h1>
        </div>
      </div>
      <div className="container m-auto mt-12 pb-12 px-4">
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          AYDINLATMA METNİ
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          KVKK uyarınca veri işleyen ve/veya veri sorumlusu sıfatıyla Bamtaş
          İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi olarak; 6698 Sayılı
          Kişisel Verilerin Korunması Kanunu'na uygun olarak elde etmiş
          olduğumuz kişisel verilerinizi, yine bu kanuna ve Anayasaya uygun
          olarak işleyip, bu verilerin işlenmesi, korunması,silinmesi ve
          güvenliği konusunda gerekli hassasiyeti göstermekteyiz.
          <br />
          <br /> Kişisel verileriniz, Hukuka ve dürüstlük kuralına uygun
          olarak,doğru ve güncel olarak, İşlenme amacına uygun ve ölçülü olarak,
          belirli ve meşru amaçlar gözetilerek ve gerekli olduğu süre kadar
          işlenmekte, muhafaza edilmektedir.
          <br />
          <br /> Kişisel verileriniz, internet sitesi, e-posta, sözleşmeler,
          başvurular, müşteri şikayetlerine ilişkin olanlar da dahil genel
          olarak formlar, teklifler, sözleşmeler aracılığıyla sözlü, yazılı veya
          elektronik ortamda toplanabilmektedir. <br />
          <br />
          6698 Sayılı Kanunun 10.maddesi uyarınca Veri Sorumlusunun Aydınlatma
          Yükümlülüğü olduğundan, ilgili kanun maddesi uyarınca aşağıdaki
          konularda sizleri bilgilendirmek istemekteyiz.
          <br />
          <br /> Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi
          olarak çalışanlarımızın, tedarikçilerimizin, taşeronlarımızın,
          internet sitemizi ziyaret eden kullanıcılarımızın, ayrıca bunlarla
          sınırlı olmamak üzere faaliyetlerimiz sırasında edindiğimiz tüm
          kişisel verilerin işlenmesi, saklanması,aktarılması ve silinmesi
          esnasında 6698 sayılı kanun hükümlerine uygun hareket edilmektedir.
        </p>
        <br></br>
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          1)VERİ SORUMLUSU OLARAK KİMLİĞİMİZ:
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi (Vergi
          Numarası:140 039 71 25 Mersis Numarası 0140039712500001, Adres:
          İvedikköy Mah. Anadolu Bulv. No:151/22 Yenimahalle / ANKARA .,
          Telefon: 444 82 71 E-Posta Adresi : info@bamtas.com Kayıtlı Elektronik
          Posta bamtas@hs01.kep.tr) 2017 yılından itibaren Türkiye genelinde
          İnşaat alanında hizmet veren sermaye şirketidir.
        </p>
        <br />
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          2)HANGİ KİŞİSEL VERİLERİNİZ İŞLENMEKTEDİR:
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi olarak
          gerekli olduğu hallerde ve hukuka uygun olarak; çalışanlarımızın, iş
          ortaklarımızın çalışanlarının, hizmet alıp hizmet verdiğimiz
          kişilerin, taşeronlarımızın, tedarikçilerimizin, sözleşme yaptığımız
          kişilerin, ihale ile ilişki halinde olduğumuz kişilerin, faaliyet
          esnasında muhatap olduğumuz kişilerin kişisel verilerini ilgili
          kanunda yazan ilke kurallarına uygun olarak işlemekteyiz. Bu kapsamda
          işlenen;
          <br />
          <br /> -Kimlik Bilgi Verileri : Ad, soyad, T.C. kimlik numarası, uyruk
          bilgisi, anne baba-çocuklarının adı, doğum yeri, doğum tarihi,
          cinsiyet, nüfus kütük bilgileri vb. bilgileri içeren nüfus cüzdanı,
          ehliyet, pasaport ve tanıtım kimlikleri gibi belgeler, kişinin SGK
          numarası, vergi numarası vb. bilgiler.
          <br />
          <br /> İletişim Bilgi Verileri : Telefon numarası, adres, e-mail
          adresi, faks numarası, IP adresi, mac adresi vb. kişi ile iletişim
          kurulacak bilgiler. <br />
          Finansal Bilgi Verileri :Banka hesap ve İBAN numarası bilgileri,
          finansal sonucu gösteren her türlü belge ve bilgi, gelir bilgileri vb.
          bilgi ve veriler. <br />
          <br />
          Resim Verisi: Fotoğraf, video görüntüleri <br />
          <br />
          Şirket Verileri: Adres, Sicil Numarası, Vergi Numarası <br />
          <br />
          Özel Nitelikli Kişisel Veriler: Sağlık Bilgileri
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          3)KİŞİSEL VERİLERİNİZİ HANGİ AMÇLARLA İŞLENMEKTEDİR:
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          -Bir sözleşmenin akdedilmesi :Kişisel verileriniz kira sözleşmesi,
          satım sözleşmesi, ariyet sözleşmesi, hizmet sözleşmesi,taşeron
          sözleşmesi, eser sözleşmesi,işçi-işveren sözleşmesi,ortaklık
          sözleşmesi dahil çeşitli sözleşmelerin hazırlanması, yürürlüğe konması
          ve sözleşmenin ifa edilebilmesi, feshedilmesi amacıyla kimlik
          verileri, şirket verileri işlenmektedir. <br />
          <br />
          -Kanundan kaynaklı zorunlulukların yerine getirebilmesi: Kanunun
          zorunlu kıldığı haller sebebiyle, hukuki yükümlülükleri yerine
          getirebilmek adına kimlik verileri, şirket verileri, sağlık verileri,
          güvenlik verileri işlenmektedir. <br />
          <br />
          -Meşru menfaatlerin korunması ve güvenliğin sağlanması; Meşru
          menfaatlerimizin zarar görmemesi ve güvenliğin korunması amacına
          yönelik olarak kimlik verileri, şirket verileri ,iletişim verileri
          işlenmektedir.. <br />
          <br />
          -Arka büro faaliyetleri yönetimi: doğru fatura düzenlenmesi,
          faturaların kontrol edilmesi, hak edişlerin yapılabilmesi,ödeme
          alınması ve ödeme yapılabilmesi, finanas ve muhasebe işlemlerinin
          takibi için verileriniz işlenmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          4)KİŞİSEL VERİLERİNİZİ KİMLERE AKTARIYORUZ:
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Yukarıda belirtilen amaçlarla, kişisel verilerinizin aktarılabileceği
          kişi/kuruluşlar; mevzuat hükümlerinin izin verdiği ve/veya zorunlu
          kıldığı kurum veya kuruluşlar ile faaliyetlerimizi yürütmek üzere
          sözleşmesel olarak hizmet aldığımız, işbirliği yaptığımız, her türlü
          kişisel verilerinizin muhafazası, yetkisiz erişimlerin önlenmesi ve
          hukuka aykırı olarak işlenmelerini önlemek gibi veri güvenliği
          tedbirlerinin alınmasında sorumlu olan diğer üçüncü kişilerdir.
          <br />
          <br /> Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi
          tarafından işlenen kişisel veriler, işlenme amaçları ile sınırlı
          olarak ve KVK Kanunu’nun 8 ve 9 uncu maddelerinde öngörülen
          düzenlemelere uygun olarak üçüncü kişilere aktarılabilecektir. <br />
          <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          kişisel verilerin aktarılması aşamasında gerekli koruma ve güvenlik
          tedbirleri alınmaktadır.
          <br />
          <br /> Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi ’nin
          gerek çalışanlarına karşı olan yükümlülüklerini gerekse
          ziyaretçileri/müşterilerine karşı sunacağı hizmet kalitesini artırmak
          amacı ile hukuki statüsü gereği kişisel veriler sadece aktarım
          amacıyla sınırlı olmak üzere üçüncü kişilere aktarılmaktadır. Bu
          kapsamda aktarım yapılabilecek üçüncü kişi sınıflandırılması aşağıdaki
          şekildedir;
        </p>
        <br />
        <table className="table-fixed text-justify pt-12 text-base sm:text-2xl px-2 py-2">
          <tbody>
            <tr>
              <td className="border px-2 font-bold">
                Veri Aktarımı Yapılabilecek Kişiler
              </td>
              <td className="border px-2 font-bold">Tanımı</td>
              <td className="border px-2 font-bold">Amacı</td>
            </tr>
            <tr>
              <td className="border px-2 font-bold ">Bankalar</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi ’nın
                faaliyetleri ve amacı kapsamında yapılacak ödeme ve menfaatler
                için anlaşılan Bankaları tanımlamaktadır.
              </td>
              <td className="border px-2">
                İş ortakları, pay sahipleri ve çalışanlar adına Banka nezdinde
                yapılan ödemeler ve Bankalar tarafından Bamtaş İnşaat Taahhüt
                Maden Sanayi Ticaret Anonim Şirketi , iş ortakları ve
                çalışanlara sağlanan menfaatlerle sınırlı olmak üzere.
              </td>
            </tr>
            <tr>
              <td className="border px-2 font-bold">Hizmet Sunucusu</td>
              <td className="border px-2">
                Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi ’nın
                amaç ve faaliyetleri ile ilgili olarak Hizmet Sözleşmesi
                imzalamak suretiyle hizmet veren gerçek ve tüzel kişileri
                tanımlamaktadır.
              </td>
              <td className="border px-2">
                Gerçek ve tüzel kişilerden alınacak amaca uygun ve hizmetle
                sınırlı olarak.
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          5)KİŞİSEL VERİ TOPLAMANIN HUKUKİ SEBEBİ VE VERİ TOPLAMA YÖNTEMLERİ:
        </h3>
        <p className="text-justify pt-12 text-base sm:text-2xl">
          Kişisel verileriniz merkez, şubeler veya diğer alt yüklenicileri veya
          iş ortakları ile iletişime geçebileceğiniz ofis ve diğer fiziki
          ortamlar, internet siteleri veya diğer Grup Şirketleri veya anlaşmalı
          oldukları diğer kişi ve kuruluşlar kanalıyla sözlü, yazılı veya diğer
          fiziksel veya elektronik ortamda işlenmektedir.Ayrıca sözleşmeler,
          teklifler, icaplar, web sitesi, elektronik posta, her türlü yazılı,
          sözlü ve elektronik ortamlar vasıtasıyla, kişisel verilerinizin
          işlenmesine yönelik açık rızanıza ya da Kanun’un 5 ve 6. maddeleri
          veya sair mevzuatta belirtilen ve aşağıda yer verilen hukuki sebeplere
          dayanarak, tamamen otomatik, kısmen otomatik ya da otomatik olmayan
          yöntemlerle toplanabilir, işlenebilir ve aktarılabilir.
          <br />
          <br />
          Kişisel Verileriniz;
          <br />
          <br />
          -Şirketin faaliyeti esnasında tabi olduğu mevzuatta öngörülmüş olması,
          <br />
          <br />
          -Fiili imkânsızlık halleri ortaya çıkması durumunda, rızanızı
          açıklayamayacak durumda bulunmanız veya rızasına hukuki geçerlilik
          tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden
          bütünlüğünün korunması için veri işlemenin zorunlu olması, <br />
          <br />
          -Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması, talep edilen ürün ve hizmetleri
          sunabilmek veya akdettiğiniz sözleşmelerin gereğinin yerine
          getirilmesi, <br />
          <br />
          -Hukuki yükümlülüklerini yerine getirebilmesi için veri işlemenin
          zorunlu olması,
          <br />
          <br /> -Tarafınızca alenileştirilmiş olması, <br />
          <br />
          -Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,
          <br />
          <br /> -Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, meşru
          menfaatleri için veri işlemenin zorunlu olması. hallerinde
          işlenmektedir.
        </p>
        <br />
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          6)İLGİLİ KİŞİNİN HAKLARI VE HAKLARIN KULLANMASI BAŞVURUSU YÖNTEMİ
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          KVK Kanunu’nun 11 inci maddesi gereği kişisel verileri işlenen ilgili
          kişi; <br />
          <br />
          -Kişisel verilerinin işlenip işlenmediğini öğrenme,
          <br />
          <br /> -Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
          -Kişisel verilerin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme, <br />
          <br />
          -.Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
          kişileri bilme, <br />
          <br />
          -Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme, <br />
          <br />
          -.KVKK 7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin
          silinmesini veya yok edilmesini isteme, <br />
          <br />
          -.Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesi ile KVKK’nun öngördüğü çerçevede kişisel verilerinizin
          silinmesini veya yok edilmesi taleplerinizin kişisel verilerin
          aktarıldığı üçüncü kişilere bildirilmesini isteme, <br />
          <br />
          -İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz
          etme, <br />
          <br />
          -Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle bir zarara
          uğramanız halinde zararın giderilmesini talep etme, haklarına sahip
          bulunmaktadır. <br />
          <br />
          Kişisel verileri işlenen kişi Bamtaş İnşaat Taahhüt Maden Sanayi
          Ticaret Anonim Şirketi başvurarak belirtilen haklara ilişkin
          taleplerini yazılı olarak bildirmesi halinde, Bamtaş İnşaat Taahhüt
          Maden Sanayi Ticaret Anonim Şirketi tarafından talebin niteliğine göre
          başvuruya 30 gün içinde herhangi bir ücret alınmaksızın cevap
          verilecektir. Ancak KVK Kurulu tarafından taleple ilgili herhangi bir
          ücret alınmasının düzenlenmesi halinde, belirlenen tarife kapsamındaki
          ücret alınabilecektir.
          <br />
          <br /> Yapılacak başvurular doğrudan Bamtaş İnşaat Taahhüt Maden
          Sanayi Ticaret Anonim Şirketi ne yazılı olarak yapılabileceği gibi,
          KVK Kurulu tarafından belirlenecek yöntemlerle yapılması da mümkündür.{" "}
          <br />
          <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi .ne yazılı
          olarak yapılacak başvurularda; talepte bulunan ilgili kişinin
          kimliğini tespit edici belgeler ile birlikte yapılan başvuruya ilişkin
          açıklama ve kullanılmak istenen hakka ilişkin taleplerin de açıkça
          belirtilmesi gerekmektedir. Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret
          Anonim Şirketi .ne yapılan başvurular ve talepler titizlikle
          incelenerek, başvuru ve talepler en geç 30 gün içinde olumlu veya
          olumsuz olarak neticelendirilecek ve neticeden ilgili kişiye bilgi
          verilecektir. <br />
          <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          yapılan başvuru ve talep haklı görülmesi halinde gereği gecikmeksizin
          yerine getirilecektir. Yapılan başvuru ve talebin reddi halinde ise,
          red gerekçesi ilgili kişiye başvuru ve talep dilekçesinde belirtildiği
          şekilde yazılı olarak veya elektronik ortamda bildirilecektir. Bamtaş
          İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından başvuru
          ve talebin red edilmesi, verilen cevabın yetersiz bulunması veya
          süresinde cevap verilmemesi hallerinde, ilgili kişinin başvuru ve
          talep cevabını öğrendiği tarihten itibaren otuz gün ve her halde
          başvuru tarihinden itibaren altmış gün içinde KVK Kurulu’na şikayette
          bulunma hakkı vardır.
        </p>
        <br></br>
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          7) VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİMLEŞTİRİLMESİ USULLERİ
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          başta Türkiye Cumhuriyeti Anayasası olmak üzere ilgili Kanun ve
          Yönetmeliklere uygun olarak işlenen kişisel veriler KVK Kanunu’nun 7
          nci maddesi hükmü gereğince; işlenmesini gerektiren sebeplerin ortadan
          kalkması hâlinde kişisel veriler Bamtaş İnşaat Taahhüt Maden Sanayi
          Ticaret Anonim Şirketi .tarafından alınacak karar ile veya ilgili
          kişinin talebi üzerine silinir, yok edilir veya anonim hâle getirilir.{" "}
          <br />
          <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          kişisel verilerin silinmesi ve yok edilmesi aşamasında; kişisel
          veriler fiziksel olarak yok edilmesi, yazılımdan güvenli yöntemlerle
          silinmesi ve gerekmesi halinde uzmanlar tarafından güvenli şekilde bir
          daha kurtarılamayacak şekilde silinerek yok edilir. <br />
          <br />
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi tarafından
          ayrıca kişisel verilerin niteliğine göre maskeleme, toplulaştırma,
          veri türetme ve karma teknikleri kullanılarak kişisel verilerin, başka
          verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya
          belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle
          getirilerek anonimleştirilir.
        </p>
        <h3 className="text-lg sm:text-3xl text-red-700 font-bold ">
          8) DEĞİŞİKLİKLER
        </h3>
        <br />
        <p className="text-justify  text-base sm:text-2xl">
          Bamtaş İnşaat Taahhüt Maden Sanayi Ticaret Anonim Şirketi Kişisel
          Verilerin Korunması Kanunu’nda olabilecek değişiklikler ve Kişisel
          Verileri Koruma Kurulu tarafından belirlenecek yöntemler nedeni ile bu
          aydınlatma bildiriminde değişiklik yapma hakkı saklıdır.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default InternetAydinlatma;
