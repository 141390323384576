import React from "react";
import { useInView } from "react-hook-inview";
import CountTo from "react-count-to";

const Numbers = () => {
  const [ref, isVisible] = useInView({});
  isVisible && console.log("gorundu");
  return (
    <section
      ref={ref}
      id="numbers"
      className={`sm:pl-4 sm:pr-4 m-auto mt-5 mb-5 sm:mt-20 sm:mb-20 `}
    >
      <h5 className="text-[#E02F32] font-bold text-2xl pt-12 md:mb-12 md:mt-12 ">
        Sayılarla Bamtaş
      </h5>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 m-auto  gap-2 sm:justify-items-center">
        <div className="flex items-center  p-4 sm:p-0 w-80">
          <div className="h-28 w-2 bg-red-700 transition-all mr-6"></div>
          <p className="text-2xl sm:text-6xl font-bold">
            {isVisible && (
              <CountTo
                to={5}
                speed={1234}
                className={"text-2xl sm:text-6xl font-bold "}
              ></CountTo>
            )}

            <br></br>
            <span className=" text-xl sm:text-2xl font-normal">
              Devam Eden Projeler
            </span>
          </p>
        </div>
        <div className="flex items-center p-4 sm:p-0 w-80">
          <div className="h-28 w-2 bg-red-700 transition-all mr-6"></div>
          <p className="text-2xl sm:text-6xl font-bold">
            {isVisible && (
              <CountTo
                to={18}
                speed={1234}
                className={"text-2xl sm:text-6xl font-bold"}
              ></CountTo>
            )}
            <br></br>
            <span className=" text-xl sm:text-2xl font-normal">
              Tamamlanan Projeler
            </span>
          </p>
        </div>
        <div className="flex items-center p-4 sm:p-0 w-80">
          <div className="h-28 w-2 bg-red-700 transition-all mr-6"></div>
          <p className="text-2xl sm:text-6xl font-bold">
            {isVisible && (
              <CountTo
                to={750000}
                speed={1234}
                className={"text-2xl sm:text-6xl font-bold "}
              ></CountTo>
            )}
            <span className="sm:text-4xl text-lg"> {"\u33A1"}</span>
            <br></br>
            <span className=" text-xl sm:text-2xl font-normal">
              İnşaat Alanı
            </span>
          </p>
        </div>
        <div className="flex items-center p-4 sm:p-0 w-80">
          <div className="h-28 w-2 bg-red-700 transition-all mr-6"></div>
          <p className="text-2xl sm:text-6xl font-bold">
          {isVisible && (
              <CountTo
                to={400000}
                speed={3000}
                className={"text-2xl sm:text-6xl font-bold"}
              ></CountTo>
            )}
            <span className="sm:text-4xl text-lg">km</span> <br></br>
            <span className=" text-xl sm:text-2xl font-normal">
              Serilen Asfalt
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
